import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useCategoryProducts = ({
  categoryId,
  sortBy,
  sortOrder,
  pageNumber,
  pageSize,
}: {
  categoryId: number;
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: [
      'category-products',
      { darkstoreId, categoryId, sortBy, sortOrder, pageNumber, pageSize },
    ],
    queryFn: () => {
      return bazarApi.assortment.getCategoryProducts({
        darkstoreId,
        categoryId,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
      });
    },
    keepPreviousData: true,
  });

  const { rows, totalPages } = data ?? { rows: [], totalPages: 0 };

  return { isFetching, products: rows, totalPages };
};
