import { z } from 'zod';
import { BlacklistAddressType } from 'shared/lib/bazar-api';

export const blacklistAddressFormSchema = z.object({
  darkstoreId: z.number().int().min(0),
  type: z.nativeEnum(BlacklistAddressType),
  street: z.string().min(1),
  house: z.string().min(1),
  building: z.string(),
  flat: z.string(),
});

export const blacklistCustomerFormSchema = z.object({
  partnerId: z.number().int().min(0),
  phone: z.string().min(1),
  minOrderSum: z.number().positive(),
  amnesty: z.number().int().positive(),
});
