import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useUsers = ({ query }: { query: string }) => {
  const partnerId = useSettings(settings => settings.partnerId);

  const { isFetching, data } = useQuery({
    queryKey: ['users', { partnerId, query }],
    queryFn: () => bazarApi.users.getList({ partnerId, query }),
  });

  return { isFetching, users: data ?? [] };
};
