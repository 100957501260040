import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useBlacklistAddresses = () => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['blacklist-addresses', { darkstoreId }],
    queryFn: async () => {
      return bazarApi.blacklist.getAddresses({ darkstoreId });
    },
  });

  return { isFetching, addresses: data ?? [] };
};
