import { useTranslation } from 'react-i18next';
import { Box, Group, Stack, Textarea, TextInput } from '@mantine/core';
import { PhoneInput } from 'shared/components';
import { Form, FormController } from 'shared/lib/form';
import { darkstoreFormSchema } from '../../schemas';
import { DarkstoreFormValues } from '../../types';
import { DarkstoreLinks } from './darkstore-links';
import { DarkstorePhones } from './darkstore-phones';
import { DarkstoreSchedule } from './darkstore-schedule';

export const DarkstoreForm = ({
  id,
  disabled,
  defaultValues,
  onSubmit,
}: {
  id: string;
  disabled?: boolean;
  defaultValues: Partial<DarkstoreFormValues>;
  onSubmit: (values: DarkstoreFormValues) => void;
}) => {
  const { t } = useTranslation('features/darkstores', { keyPrefix: 'darkstore-form' });

  return (
    <Form
      id={id}
      disabled={disabled}
      defaultValues={defaultValues}
      validationSchema={darkstoreFormSchema}
      onSubmit={onSubmit}
    >
      <Stack>
        <DarkstoreSchedule />
        <Group grow>
          <Box>
            <FormController
              name="name"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  label={t('name')}
                  placeholder={t('input-name')}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Box>
          <Box>
            <FormController
              name="address"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  disabled
                  required
                  label={t('address')}
                  placeholder={t('input-address')}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Box>
          <Box>
            <FormController
              name="phone"
              render={({ field, fieldState }) => (
                <PhoneInput
                  {...field}
                  required
                  label={t('phone')}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Box>
        </Group>
        <Group grow align="flex-start">
          <Box>
            <DarkstorePhones />
          </Box>
          <Box>
            <DarkstoreLinks />
          </Box>
          <Box>
            <FormController
              name="legal"
              render={({ field, fieldState }) => (
                <Textarea
                  {...field}
                  required
                  autosize
                  minRows={5}
                  label={t('legal')}
                  placeholder={t('input-legal')}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Box>
        </Group>
      </Stack>
    </Form>
  );
};
