import { Subcategory } from 'shared/lib/bazar-api';
import { useEditSubcategory } from '../hooks';
import { SubcategoryForm } from './subcategory-form';

export const SubcategoryEditModal = ({
  subcategory,
  onEdit,
}: {
  subcategory: Subcategory;
  onEdit: () => void;
}) => {
  const { isEditing, edit } = useEditSubcategory({ onSuccess: onEdit });

  return (
    <SubcategoryForm
      isSubmitting={isEditing}
      defaultValues={subcategory}
      onSubmit={data => edit({ subcategoryId: subcategory.id, data })}
    />
  );
};
