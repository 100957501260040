import { useSetState } from '@mantine/hooks';
import { TableState } from '@tanstack/react-table';

export const useDataGridState = (
  initialState: Partial<TableState>,
): [
  TableState & {
    server: { sortBy: string; sortOrder: string; pageNumber: number; pageSize: number };
  },
  (statePartial: Partial<TableState> | ((currentState: TableState) => Partial<TableState>)) => void,
] => {
  const [state, setState] = useSetState<TableState>(initialState as TableState);

  const server = {
    sortBy: state.sorting?.[0]?.id,
    sortOrder: state.sorting?.[0]?.desc ? 'descending' : 'ascending',
    pageNumber: state.pagination?.pageIndex + 1,
    pageSize: state.pagination?.pageSize,
  };

  return [{ ...state, server }, setState];
};
