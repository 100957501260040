import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { BASE_URL } from 'shared/config/env';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${BASE_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    detection: {
      lookupLocalStorage: 'language',
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    supportedLngs: ['en', 'ru', 'uk', 'pl'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
