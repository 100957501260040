import { WriteOffReason, WriteOffType } from 'shared/lib/bazar-api';

export const WRITE_OFF_REASONS = [
  { label: 'Списание', value: WriteOffReason.WriteOff },
  { label: 'Возврат', value: WriteOffReason.Return },
  { label: 'Перемещение', value: WriteOffReason.Moving },
  { label: 'Недостача', value: WriteOffReason.Lack },
  { label: 'Пересортица', value: WriteOffReason.ReGrading },
];

export const WRITE_OFF_TYPES = [
  { label: '+/- ', value: WriteOffType.Any },
  { label: '+', value: WriteOffType.Plus },
  { label: '- ', value: WriteOffType.Minus },
];
