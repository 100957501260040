import { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteItem, Loader, Sx } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Address } from 'shared/lib/bazar-api';
import { useAddresses } from '../hooks';

export const AddressAutocomplete = ({
  className,
  sx,
  required,
  label,
  value,
  error,
  onChange,
}: {
  className?: string;
  sx?: Sx;
  required?: boolean;
  label?: string;
  value?: string;
  error?: string;
  onChange: (item: Address) => void;
}) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 300);
  const { isFetching, addresses } = useAddresses({ query: debouncedQuery });

  useEffect(() => setQuery(value ?? ''), [value]);

  const handleItemSubmit = (item: AutocompleteItem) => {
    const address = addresses.find(a => a.name === item.value);
    if (address) {
      setQuery(address.name);
      onChange(address);
    }
  };

  return (
    <Autocomplete
      className={className}
      sx={sx}
      required={required}
      label={label}
      placeholder="Например, улица Леонардо Да Винчи, 1"
      data={addresses.map(a => ({ value: a.name }))}
      value={query}
      error={error}
      filter={() => true}
      rightSection={isFetching && <Loader size="xs" />}
      onChange={setQuery}
      onBlur={() => setQuery(value ?? '')}
      onItemSubmit={handleItemSubmit}
    />
  );
};
