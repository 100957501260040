import { bazarApiClient } from '../client';
import { Address } from '../types';

const search = async ({ query }: { query: string }): Promise<Address[]> => {
  const res = await bazarApiClient.get('v2/addresses', { searchParams: { q: query } });
  const data = await res.json<any[]>();
  return data;
};

export const addresses = { search };
