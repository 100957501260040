import { Category, Subcategory } from 'shared/lib/bazar-api';
import { useAddSubcategory } from '../hooks';
import { SubcategoryForm } from './subcategory-form';

export const SubcategoryAddModal = ({
  categoryId,
  order,
  onAdd,
}: {
  categoryId: Category['id'];
  order: Subcategory['order'];
  onAdd: () => void;
}) => {
  const { isAdding, add } = useAddSubcategory({ onSuccess: onAdd });

  return (
    <SubcategoryForm
      isSubmitting={isAdding}
      defaultValues={{ categoryId, name: '', order }}
      onSubmit={data => add({ data })}
    />
  );
};
