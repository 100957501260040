import { useQuery } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';

export const useSupplies = ({
  createdAt,
  expectedAt,
  suppliersIds,
  darkstoresIds,
  sortBy,
  sortOrder,
  pageNumber,
  pageSize,
}: {
  createdAt?: Date;
  expectedAt?: Date;
  suppliersIds?: number[];
  darkstoresIds?: number[];
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}) => {
  const { isFetching, data } = useQuery({
    queryKey: [
      'supplies',
      {
        createdAt,
        expectedAt,
        suppliersIds,
        darkstoresIds,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
      },
    ],
    queryFn: async () => {
      return bazarApi.supplies.getList({
        createdAt,
        expectedAt,
        suppliersIds,
        darkstoresIds,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
      });
    },
  });

  const { rows, totalPages } = data ?? { rows: [], totalPages: 0 };

  return { isFetching, supplies: rows, totalPages };
};
