import { useTranslation } from 'react-i18next';
import { Button, Radio, Stack } from '@mantine/core';
import { CategorySelect, SubcategorySelect } from 'features/categories';
import { FileDropzone } from 'shared/components';
import { BannerActionType, FileType } from 'shared/lib/bazar-api';
import { Form, FormController, FormWatch } from 'shared/lib/form';
import { BANNER_ACTION_TYPES, BANNER_SIZES } from '../../constants';
import { bannerFormSchema } from '../../schemas';
import type { BannerFormValues } from '../../types';

export const BannerForm = ({
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  isSubmitting: boolean;
  defaultValues: Partial<BannerFormValues>;
  onSubmit: (values: BannerFormValues) => void;
}) => {
  const { t } = useTranslation('features/banners', { keyPrefix: 'banner-form' });

  return (
    <Form defaultValues={defaultValues} validationSchema={bannerFormSchema} onSubmit={onSubmit}>
      <Stack align="stretch">
        <FormController
          name="actionType"
          render={({ field }) => (
            <Radio.Group
              {...field}
              size="sm"
              orientation="vertical"
              spacing="sm"
              label={t('action-type')}
            >
              {BANNER_ACTION_TYPES.map(t => (
                <Radio key={t.value} label={t.label} value={t.value} />
              ))}
            </Radio.Group>
          )}
        />
        <FormController
          name="webImage"
          render={({ field, fieldState }) => (
            <FileDropzone
              type={FileType.Banner}
              required
              label={t('web-image')}
              error={fieldState.error?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FormController
          name="appImage"
          render={({ field, fieldState }) => (
            <FileDropzone
              type={FileType.Banner}
              required
              label={t('app-image')}
              error={fieldState.error?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FormWatch
          name="actionType"
          render={actionType => (
            <>
              {actionType === BannerActionType.Category && (
                <FormController
                  name="categoryId"
                  render={({ field, fieldState }) => (
                    <CategorySelect
                      {...field}
                      label={t('category')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              )}
              {actionType === BannerActionType.Subcategory && (
                <>
                  <FormController
                    name="categoryId"
                    render={({ field, fieldState }) => (
                      <CategorySelect
                        {...field}
                        label={t('category')}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <FormController
                    name="subcategoryId"
                    render={({ field, fieldState }) => (
                      <FormWatch
                        name="categoryId"
                        render={categoryId => (
                          <SubcategorySelect
                            {...field}
                            categoryId={categoryId}
                            label={t('subcategory')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </>
              )}
              {actionType === BannerActionType.Stories && (
                <FormController
                  name="image"
                  render={({ field, fieldState }) => (
                    <FileDropzone
                      type={FileType.Banner}
                      label={t('stories-image')}
                      required
                      error={fieldState.error?.message}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              )}
            </>
          )}
        />
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t('save')}
        </Button>
      </Stack>
    </Form>
  );
};
