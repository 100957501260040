import { bazarApiClient } from '../client';
import { BlacklistAddress, BlacklistCustomer, Darkstore } from '../types';

const getAddresses = async ({
  darkstoreId,
}: {
  darkstoreId: Darkstore['id'];
}): Promise<BlacklistAddress[]> => {
  const res = await bazarApiClient.get('admin/customers/blacklist', {
    searchParams: { darkstoreId },
  });
  const data = await res.json<any[]>();
  return data.map(item => ({
    id: item.id,
    darkstoreId,
    type: item.type,
    street: item.addressStreet,
    building: item.addressCorps,
    house: item.addressBuilding,
    flat: item.addressFlat,
  }));
};

const addAddress = async (variables: Omit<BlacklistAddress, 'id'>) => {
  const body = JSON.stringify({
    darkstoreId: variables.darkstoreId,
    type: variables.type,
    addressStreet: variables.street,
    addressCorps: variables.building,
    addressBuilding: variables.house,
    addressFlat: variables.flat,
  });
  const res = await bazarApiClient.post('admin/customers/blacklist', { body });
  const data = await res.json();
  return data;
};

const editAddress = async (variables: BlacklistAddress) => {
  const body = JSON.stringify({
    id: variables.id,
    darkstoreId: variables.darkstoreId,
    type: variables.type,
    addressStreet: variables.street,
    addressCorps: variables.building,
    addressBuilding: variables.house,
    addressFlat: variables.flat,
  });
  const res = await bazarApiClient.put('admin/customers/blacklist', { body });
  const data = await res.json();
  return data;
};

const removeAddress = async ({ id }: { id: BlacklistAddress['id'] }) => {
  const res = await bazarApiClient.delete(`admin/customers/blacklist/${id}`);
  const data = await res.json();
  return data;
};

const getCustomers = async ({
  partnerId,
  query,
}: {
  partnerId: number;
  query: string;
}): Promise<BlacklistCustomer[]> => {
  const res = await bazarApiClient.get('admin/phoneBlacklist', {
    searchParams: { partnerId, phone: query },
  });
  const data = await res.json<any[]>();
  return data.map(item => ({
    id: item.id,
    partnerId,
    phone: item.phone,
    minOrderSum: item.minSum,
    amnesty: item.amnesty,
  }));
};

const addCustomer = async (variables: Omit<BlacklistCustomer, 'id'>) => {
  const body = JSON.stringify({
    partnerId: variables.partnerId,
    phone: variables.phone,
    minSum: variables.minOrderSum,
    amnesty: variables.amnesty,
  });
  const res = await bazarApiClient.post('admin/phoneBlacklist', { body });
  const data = await res.json();
  return data;
};

const editCustomer = async (variables: BlacklistCustomer) => {
  const body = JSON.stringify({
    id: variables.id,
    partnerId: variables.partnerId,
    phone: variables.phone,
    minSum: variables.minOrderSum,
    amnesty: variables.amnesty,
  });
  const res = await bazarApiClient.put('admin/phoneBlacklist', { body });
  const data = await res.json();
  return data;
};

const removeCustomer = async ({ id }: { id: BlacklistCustomer['id'] }) => {
  const res = await bazarApiClient.delete(`admin/phoneBlacklist/${id}`);
  const data = await res.json();
  return data;
};

export const blacklist = {
  getAddresses,
  addAddress,
  editAddress,
  removeAddress,
  getCustomers,
  addCustomer,
  editCustomer,
  removeCustomer,
};
