import { useQuery } from '@tanstack/react-query';
import { bazarApi, Category } from 'shared/lib/bazar-api';

export const useSubcategories = ({ categoryId }: { categoryId?: Category['id'] }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['subcategories', { categoryId }],
    queryFn: () => {
      if (categoryId) {
        return bazarApi.assortment.getSubcategories({ categoryId });
      }
    },
    enabled: !!categoryId,
  });

  return { isFetching, subcategories: data ?? [] };
};
