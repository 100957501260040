import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { ConfirmModal } from 'shared/components';
import { Subcategory } from 'shared/lib/bazar-api';
import { useRemoveSubcategory } from '../hooks';

export const SubcategoryRemoveModal = ({
  subcategory,
  onClose,
}: {
  subcategory: Subcategory;
  onClose: () => void;
}) => {
  const { t } = useTranslation('features/categories', { keyPrefix: 'subcategory-remove-modal' });

  const { isRemoving, remove } = useRemoveSubcategory({ onSuccess: onClose });

  return (
    <ConfirmModal
      labels={{ confirm: t('remove-subcategory'), cancel: t('dont-remove-subcategory') }}
      confirmProps={{ loading: isRemoving, color: 'red' }}
      onCancel={onClose}
      onConfirm={() => remove({ subcategoryId: subcategory.id })}
    >
      <Text size="sm">{t('subcategory-remove-confirmation')}</Text>
    </ConfirmModal>
  );
};
