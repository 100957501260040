import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { ConfirmModal } from 'shared/components';
import { BlacklistCustomer } from 'shared/lib/bazar-api';
import { useRemoveBlacklistCustomer } from '../hooks';

export const BlacklistCustomerRemoveModal = ({
  customer,
  onClose,
}: {
  customer: BlacklistCustomer;
  onClose: () => void;
}) => {
  const { t } = useTranslation('features/blacklist', {
    keyPrefix: 'blacklist-customer-remove-modal',
  });

  const { isRemoving, remove } = useRemoveBlacklistCustomer({ onSuccess: onClose });

  return (
    <ConfirmModal
      labels={{
        confirm: t('remove-blacklist-customer'),
        cancel: t('dont-remove-blacklist-customer'),
      }}
      confirmProps={{ loading: isRemoving, color: 'red' }}
      onCancel={onClose}
      onConfirm={() => remove({ customerId: customer.id })}
    >
      <Text size="sm">{t('blacklist-customer-remove-confirmation')}</Text>
    </ConfirmModal>
  );
};
