import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, User } from 'shared/lib/bazar-api';

type RemoveUserDTO = {
  userId: User['id'];
};

export const useRemoveUser = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: RemoveUserDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, RemoveUserDTO>({
    mutationFn: variables => bazarApi.users.remove({ id: variables.userId }),
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Пользователь удален',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      onSuccess(data, variables);
    },
  });

  return { isRemoving: isLoading, remove: mutate };
};
