export const ReportsIcon = ({
  size = 24,
  color = 'currentColor',
  ...restProps
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
      {...restProps}
    >
      {/* <g clip-path="url(#a)"> */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.321 11H21.93V6.714c0-.394-.304-.714-.679-.714H7.679C7.304 6 7 6.32 7 6.714v16.429C7 24.72 8.215 26 9.714 26h13.572C24.785 26 26 24.72 26 23.143V11.714c0-.394-.304-.714-.679-.714ZM12.43 9.571H16.5c.375 0 .679.32.679.715 0 .394-.304.714-.679.714h-4.071c-.375 0-.679-.32-.679-.714 0-.395.304-.715.679-.715Zm6.107 13.572h-8.143c-.375 0-.679-.32-.679-.715 0-.394.304-.714.679-.714h8.143c.374 0 .678.32.678.715 0 .394-.303.714-.678.714Zm0-2.857h-8.143c-.375 0-.679-.32-.679-.715 0-.394.304-.714.679-.714h8.143c.374 0 .678.32.678.714 0 .395-.303.715-.678.715Zm0-2.857h-8.143c-.375 0-.679-.32-.679-.715 0-.394.304-.714.679-.714h8.143c.374 0 .678.32.678.714 0 .395-.303.715-.678.715Zm0-2.858h-8.143c-.375 0-.679-.32-.679-.714 0-.394.304-.714.679-.714h8.143c.374 0 .678.32.678.714 0 .395-.303.714-.678.714Zm6.107 8.572c0 .789-.608 1.428-1.357 1.428-.75 0-1.357-.64-1.357-1.428V12.429h2.714v10.714Z"
      />
      {/* </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(7 6)" d="M0 0h19v20H0z" />
        </clipPath>
      </defs> */}
    </svg>
  );
};
