import { ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Alert, Button, Text } from '@mantine/core';
import { QueryErrorResetBoundary } from '@tanstack/react-query';

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ReactErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) => (
            <Alert title="Error!" color="blue">
              <Text sx={{ marginBottom: 8 }}>{error.message}</Text>
              <Button onClick={() => resetErrorBoundary()}>Try again</Button>
            </Alert>
          )}
        >
          {children}
        </ReactErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
