import { useTranslation } from 'react-i18next';
import { Anchor, Box, Group, Input, Stack, TextInput } from '@mantine/core';
import { PhoneInput } from 'shared/components';
import { FormController, useFormFieldArray } from 'shared/lib/form';
import { DarkstoreFormValues } from '../../types';

export const DarkstorePhones = () => {
  const { t } = useTranslation('features/darkstores', { keyPrefix: 'darkstore-form' });

  const { fields, append, remove } = useFormFieldArray<DarkstoreFormValues, 'phones'>({
    name: 'phones',
  });

  return (
    <Stack>
      {fields.map((field, index) => (
        <Box key={field.id} sx={{ position: 'relative' }}>
          <Input.Label required>{t('phone')}</Input.Label>
          <Group grow align="flex-start">
            <Box>
              <FormController
                name={`phones.${index}.icon`}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    disabled
                    placeholder={t('input-icon')}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Box>
              <FormController
                name={`phones.${index}.phone`}
                render={({ field, fieldState }) => (
                  <PhoneInput {...field} error={fieldState.error?.message} />
                )}
              />
              {index === 0 ? (
                <Anchor
                  sx={{ position: 'absolute', top: 2, right: 0 }}
                  size="xs"
                  onClick={() => append({ icon: '', phone: '' })}
                >
                  {t('add-phone')}
                </Anchor>
              ) : (
                <Anchor
                  sx={{ position: 'absolute', top: 2, right: 0 }}
                  size="xs"
                  onClick={() => remove(index)}
                >
                  {t('remove-phone')}
                </Anchor>
              )}
            </Box>
          </Group>
        </Box>
      ))}
    </Stack>
  );
};
