import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Group, Stack, Title } from '@mantine/core';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import { SuppliersDataGrid } from 'features/suppliers';
import { SearchInput } from 'shared/components';
import { useSearch } from 'shared/lib/search';

export const SuppliersPage = () => {
  const [search, setSearch] = useSearch({ query: '' });

  const { t } = useTranslation('pages/suppliers');

  const hasWritePermission = usePermission('suppliers.write');

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('suppliers')}</Title>
      <Group>
        <SearchInput
          sx={{ flex: 1 }}
          value={search.query}
          onChange={query => setSearch({ query })}
        />
        <Button
          disabled={!hasWritePermission}
          leftIcon={<PlusIcon size={14} />}
          component={Link}
          to="add"
        >
          {t('add-supplier')}
        </Button>
      </Group>
      <SuppliersDataGrid query={search.query} />
    </Stack>
  );
};
