import { useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { SignInForm, useAuth, useSignIn } from 'features/auth';
import { useSearch } from 'shared/lib/search';

export const SignInPage = () => {
  const navigate = useNavigate();

  const [search] = useSearch({ redirect: '/' });

  const auth = useAuth();

  const { isSigningIn, signIn } = useSignIn({
    onSuccess: user => {
      auth.signIn(user);
      navigate(search.redirect);
    },
  });

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
      }}
    >
      <SignInForm
        isSubmitting={isSigningIn}
        defaultValues={{ email: '', password: '' }}
        onSubmit={signIn}
      />
    </Box>
  );
};
