import { useSettings } from 'features/settings';
import { useAddBlacklistCustomer } from '../hooks';
import { BlacklistCustomerForm } from './blacklist-customer-form';

export const BlacklistCustomerAddModal = ({ onClose }: { onClose: () => void }) => {
  const partnerId = useSettings(settings => settings.partnerId);

  const { isAdding, add } = useAddBlacklistCustomer({ onSuccess: onClose });

  return (
    <BlacklistCustomerForm
      isSubmitting={isAdding}
      defaultValues={{
        partnerId,
        phone: '',
        minOrderSum: 0,
        amnesty: 0,
      }}
      onSubmit={data => add({ data })}
    />
  );
};
