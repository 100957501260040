import { useCallback } from 'react';
import { Box } from '@mantine/core';
import { flexRender } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { EditableCell } from './editable-cell';
import { useDataGridContext } from './use-data-grid-context';

export const DataGridBody = () => {
  const { table, scrollElement } = useDataGridContext();
  const { rows } = table.getRowModel();

  const parentRef = scrollElement || null;

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef,
    estimateSize: useCallback(() => 40, []),
  });

  type VirtualRow = ReturnType<typeof rowVirtualizer.getVirtualItems>[number];

  return (
    <Box
      component="tbody"
      sx={{
        position: 'relative',
        width: '100%',
      }}
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow: VirtualRow) => {
        const row = rows[virtualRow.index];
        return (
          <tr
            key={row.id}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'flex',
              flex: '1 0 auto',
              width: '100%',
              minWidth: '0px',
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
              backgroundColor: virtualRow.index % 2 === 0 ? '#f8f9fa' : '#ffffff',
            }}
          >
            {row.getVisibleCells().map(cell => {
              if (cell.column.columnDef.meta?.editable) {
                cell.column.columnDef.cell = EditableCell;
              }
              return (
                <td
                  key={cell.id}
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    ...(cell.column.columnDef.meta?.sticky
                      ? {
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                        }
                      : {}),
                    flex: `${cell.column.getSize()} 0 auto`,
                    width: cell.column.getSize(),
                    minWidth: '0px',
                  }}
                  onClick={() => {
                    table.options.meta?.onCellClick?.({
                      rowId: row.id,
                      columnId: cell.column.id,
                      value: cell.getValue(),
                    });
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </Box>
  );
};
