import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Supplier } from 'shared/lib/bazar-api';
import type { SupplierFormValues } from '../types';

type AddSupplierDTO = {
  data: SupplierFormValues;
};

export const useAddSupplier = ({
  onSuccess,
}: {
  onSuccess: (data: { id: Supplier['id'] }, variables: AddSupplierDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<{ id: Supplier['id'] }, Error, AddSupplierDTO>({
    mutationFn: variables => bazarApi.suppliers.add(variables.data),
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Поставщик добавлен',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
