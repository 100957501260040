import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mantine/core';
import { useAddCustomer } from '../hooks';
import { CustomerForm } from './customer-form';

export const CustomerAddModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('features/customers', { keyPrefix: 'customer-add-modal' });

  const { isAdding, add } = useAddCustomer({ onSuccess: onClose });

  return (
    <Stack>
      <CustomerForm
        id="customer-form"
        defaultValues={{
          name: '',
          surname: '',
          bornAt: null,
          phone: '',
          addresses: [
            {
              street: '',
              house: '',
              building: '',
              flat: '',
              entrance: '',
              floor: '',
              intercom: '',
            },
          ],
        }}
        onSubmit={data => add({ data })}
      />
      <Button
        type="submit"
        form="customer-form"
        loading={isAdding}
        sx={{ display: 'block', marginLeft: 'auto' }}
      >
        {t('save')}
      </Button>
    </Stack>
  );
};
