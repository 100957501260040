import { useTranslation } from 'react-i18next';
import { Button, Group, Stack } from '@mantine/core';
import { z } from 'zod';
import { CategorySelect, SubcategorySelect } from 'features/categories';
import { SupplierSelect } from 'features/suppliers';
import { SearchInput } from 'shared/components';
import { Form, FormController, FormWatch } from 'shared/lib/form';

const validationSchema = z.object({
  query: z.string(),
  categoryId: z.number().int().min(0).optional(),
  subcategoryId: z.number().int().min(0).optional(),
  supplierId: z.number().int().min(0).optional(),
});

type FiltersFormValues = z.infer<typeof validationSchema>;

export const RegistryFiltersForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FiltersFormValues>;
  onSubmit: (values: FiltersFormValues) => void;
}) => {
  const { t } = useTranslation('features/registry', { keyPrefix: 'registry-filters-form' });

  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Stack spacing="xs">
        <FormController
          name="query"
          render={({ field, fieldState }) => (
            <SearchInput {...field} error={fieldState.error?.message} />
          )}
        />
        <Group position="apart" align="flex-end">
          <Group>
            <FormController
              name="categoryId"
              render={({ field, fieldState }) => (
                <CategorySelect
                  {...field}
                  label={t('category')}
                  error={fieldState.error?.message}
                />
              )}
            />
            <FormController
              name="subcategoryId"
              render={({ field, fieldState }) => (
                <FormWatch
                  name="categoryId"
                  render={categoryId => (
                    <SubcategorySelect
                      {...field}
                      categoryId={categoryId}
                      label={t('subcategory')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
            <FormController
              name="supplierId"
              render={({ field, fieldState }) => (
                <SupplierSelect
                  {...field}
                  label={t('supplier')}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Group>
          <Button type="submit">{t('apply-filters')}</Button>
        </Group>
      </Stack>
    </Form>
  );
};
