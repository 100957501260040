import { CellContext } from '@tanstack/react-table';
import { useDarkstores } from 'features/darkstores';
import { User } from 'shared/lib/bazar-api';

export const DarkstoresCell = (params: CellContext<User, User['darkstores']>) => {
  const { darkstores } = useDarkstores({ refetchOnMount: false });
  const value = params.getValue();
  return (
    <>
      {darkstores
        .filter(d => value.includes(d.id))
        .map(d => d.name)
        .join(', ')}
    </>
  );
};
