import { useTranslation } from 'react-i18next';
import { Group, Stack, Title } from '@mantine/core';
import { DatePicker, DateRangePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { DarkstoreSelect } from 'features/darkstores';
import { useSettings } from 'features/settings';
import { SupplierSelect } from 'features/suppliers';
import { WriteOffReasonSelect, WriteOffsDataGrid, WriteOffTypeSelect } from 'features/write-offs';
import { Darkstore, Supplier, WriteOffReason, WriteOffType } from 'shared/lib/bazar-api';
import { useSearch } from 'shared/lib/search';
import { formatDate, parseDate } from 'shared/utils/date';

export const WriteOffsPage = () => {
  const { t } = useTranslation('pages/write-offs');

  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const [search, setSearch] = useSearch<{
    darkstoreId?: Darkstore['id'];
    reason: WriteOffReason;
    month: number;
    year: number;
    supplierId?: Supplier['id'];
    from: string;
    to: string;
    type: WriteOffType;
  }>({
    darkstoreId,
    reason: WriteOffReason.WriteOff,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    supplierId: undefined,
    from: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    to: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    type: WriteOffType.Any,
  });

  const filters = { ...search, from: parseDate(search.from), to: parseDate(search.to) };

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('write-offs')}</Title>
      <Group>
        <DarkstoreSelect
          clearable
          label={t('darkstore')}
          value={filters.darkstoreId}
          onChange={darkstoreId => setSearch({ darkstoreId })}
        />
        <WriteOffReasonSelect
          label={t('reason')}
          value={filters.reason}
          onChange={reason => setSearch({ reason })}
        />
        {filters.reason === WriteOffReason.Return ? (
          <>
            <SupplierSelect
              clearable
              label={t('supplier')}
              value={filters.supplierId}
              onChange={supplierId => setSearch({ supplierId })}
            />
            <DateRangePicker
              clearable={false}
              label={t('period')}
              placeholder={t('select-period')}
              value={[filters.from, filters.to]}
              onChange={([from, to]) => {
                if (from && to) {
                  setSearch({
                    from: formatDate(from, 'YYYY-MM-DD'),
                    to: formatDate(to, 'YYYY-MM-DD'),
                  });
                }
              }}
            />
          </>
        ) : (
          <DatePicker
            clearable={false}
            label={t('date')}
            placeholder={t('select-date')}
            value={parseDate(`${filters.year}-${filters.month}-01`, 'YYYY-M-DD')}
            onChange={date => {
              if (date) {
                setSearch({ month: date.getMonth() + 1, year: date.getFullYear() });
              }
            }}
          />
        )}
        {[WriteOffReason.Moving, WriteOffReason.Lack, WriteOffReason.ReGrading].includes(
          filters.reason,
        ) && (
          <WriteOffTypeSelect
            label={t('type')}
            value={filters.type}
            onChange={type => setSearch({ type })}
          />
        )}
      </Group>
      <WriteOffsDataGrid {...filters} />
    </Stack>
  );
};
