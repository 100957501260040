import { useTranslation } from 'react-i18next';
import { Stack, Title } from '@mantine/core';
import { SuppliesDataGrid, SuppliesFiltersForm } from 'features/supplies';
import { useSearch } from 'shared/lib/search';
import { formatDate, parseDate } from 'shared/utils/date';

export const SuppliesPage = () => {
  const [search, setSearch] = useSearch<{
    darkstoresIds?: number[];
    suppliersIds?: number[];
    createdAt?: string;
    expectedAt?: string;
  }>({
    darkstoresIds: undefined,
    suppliersIds: undefined,
    createdAt: undefined,
    expectedAt: undefined,
  });

  const { t } = useTranslation('pages/supplies');

  const filters = {
    ...search,
    createdAt: search.createdAt ? parseDate(search.createdAt) : undefined,
    expectedAt: search.expectedAt ? parseDate(search.expectedAt) : undefined,
  };

  const handleFiltersChange = (filters: {
    darkstoresIds?: number[];
    suppliersIds?: number[];
    createdAt?: Date | null;
    expectedAt?: Date | null;
  }) => {
    setSearch({
      ...filters,
      createdAt: filters.createdAt ? formatDate(filters.createdAt, 'YYYY-MM-DD') : undefined,
      expectedAt: filters.expectedAt ? formatDate(filters.expectedAt, 'YYYY-MM-DD') : undefined,
    });
  };

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('supplies')}</Title>
      <SuppliesFiltersForm defaultValues={filters} onSubmit={handleFiltersChange} />
      <SuppliesDataGrid {...filters} />
    </Stack>
  );
};
