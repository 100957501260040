import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

export const formatPhone = (str: string) => {
  const cleaned = str.replace(/\D/g, '');
  const phoneStr = `+${cleaned}`;
  const isValid = isValidPhoneNumber(phoneStr);
  if (!isValid) {
    return str;
  }
  const phone = parsePhoneNumber(phoneStr);
  if (!phone) {
    return str;
  }
  return phone.formatInternational();
};

export const validatePhone = (str: string) => {
  return isValidPhoneNumber(str.startsWith('+') ? str : `+${str}`);
};
