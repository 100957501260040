import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { useSettings } from 'features/settings';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { round } from 'shared/utils/number';

export const RegistryDataGrid = ({ isLoading, data }: { isLoading: boolean; data: any[] }) => {
  const darkstoreId = useSettings(darkstoreId => darkstoreId);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();

    return [
      columnHelper.accessor('id', {
        meta: { sticky: true },
        header: 'Id',
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`/products/${value}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('name', { size: 250, header: 'Название' }),
      columnHelper.accessor('weight', { header: 'Вес' }),
      columnHelper.accessor('cod', { header: 'Штрихкод' }),
      columnHelper.accessor('pricePurchases', { header: 'Цена закупки' }),
      columnHelper.accessor('vat', { header: 'НДС' }),
      columnHelper.accessor(row => round(row.pricePurchases * (1 + row.vat / 100)), {
        id: 'priceWithVat',
        header: 'Цена с НДС',
      }),
      columnHelper.accessor('extraCharge', { header: 'Наценка' }),
      columnHelper.accessor('price', { header: 'Цена реализации' }),
      columnHelper.accessor(
        row => {
          const discount = row.discounts.find((d: any) => d.darkstoreId === darkstoreId);
          return discount ? discount.discountSize : 0;
        },
        { id: 'discount', header: 'Скидка' },
      ),
      columnHelper.accessor('providerName', { header: 'Поставщик' }),
    ];
  }, [darkstoreId]);

  return (
    <DataGrid
      loading={isLoading}
      columns={columns}
      data={data}
      sorting
      sx={{ minHeight: '500px' }}
    />
  );
};
