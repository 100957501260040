import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { SupplyRow } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid, useDataGridState } from 'shared/lib/data-grid';
import { formatDate } from 'shared/utils/date';
import { useSupplies } from '../hooks';
import { SupplyStatusBadge } from './supply-status-badge';

export const SuppliesDataGrid = ({
  createdAt,
  expectedAt,
  suppliersIds,
  darkstoresIds,
}: {
  createdAt?: Date;
  expectedAt?: Date;
  suppliersIds?: number[];
  darkstoresIds?: number[];
}) => {
  const { t } = useTranslation('features/supplies', { keyPrefix: 'supplies-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SupplyRow>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue().toString();
          return (
            <Anchor component={Link} to={value}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('number', {
        size: 150,
        header: () => t('columns.1'),
      }),
      columnHelper.accessor('darkstoreName', {
        size: 150,
        header: () => t('columns.2'),
      }),
      columnHelper.accessor('supplierName', {
        size: 150,
        header: () => t('columns.3'),
      }),
      columnHelper.accessor('status', {
        size: 150,
        header: () => t('columns.4'),
        cell: params => <SupplyStatusBadge status={params.getValue()} />,
      }),
      columnHelper.accessor('createdAt', {
        size: 150,
        header: () => t('columns.5'),
        cell: params => formatDate(params.getValue()),
      }),
      columnHelper.accessor('expectedAt', {
        size: 150,
        header: () => t('columns.6'),
        cell: params => formatDate(params.getValue()),
      }),
      columnHelper.accessor('sumWithVat', {
        size: 100,
        header: () => t('columns.7'),
      }),
      columnHelper.accessor('amount', {
        size: 100,
        header: () => t('columns.8'),
      }),
    ];
  }, [t]);

  const [state, setState] = useDataGridState({
    sorting: [{ id: 'id', desc: true }],
    pagination: { pageIndex: 0, pageSize: 30 },
  });

  const { isFetching, supplies, totalPages } = useSupplies({
    createdAt,
    expectedAt,
    suppliersIds,
    darkstoresIds,
    ...state.server,
  });

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={supplies}
      sorting
      manualSorting
      pagination
      manualPagination
      pageCount={totalPages}
      state={state}
      onStateChange={setState}
    />
  );
};
