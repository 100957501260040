import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';

type RemoveSubcategoryDTO = {
  subcategoryId: number;
};

export const useRemoveSubcategory = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: RemoveSubcategoryDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, RemoveSubcategoryDTO>({
    mutationFn: variables => bazarApi.assortment.removeSubcategory({ id: variables.subcategoryId }),
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Подкатегория удалена',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['subcategories'] });
      onSuccess(data, variables);
    },
  });

  return { isRemoving: isLoading, remove: mutate };
};
