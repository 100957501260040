import { forwardRef, useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import RPNIComponent, { Props as RPNIProps } from 'react-phone-number-input';
//@ts-ignore
import en from 'react-phone-number-input/locale/en';
//@ts-ignore
import pl from 'react-phone-number-input/locale/pl';
//@ts-ignore
import ru from 'react-phone-number-input/locale/ru';
//@ts-ignore
import ua from 'react-phone-number-input/locale/ua';
import {
  Input,
  Menu,
  ActionIcon,
  ScrollArea,
  InputProps,
  InputWrapperProps,
  Box,
  Skeleton,
} from '@mantine/core';

const useLabels = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useMemo(() => {
    switch (language) {
      case 'en':
        return en;
      case 'uk':
        return ua;
      case 'pl':
        return pl;
      default:
        return ru;
    }
  }, [language]);
};

const CountrySelect = (props: {
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
}) => {
  const { options, value, onChange } = props;

  return (
    <Menu position="bottom-start" width="250px">
      <Menu.Target>
        <Box sx={{ position: 'absolute', zIndex: 1, left: '0px', padding: '0px 6px' }}>
          <ActionIcon variant="transparent">
            {value ? (
              <ReactCountryFlag svg countryCode={value.toLowerCase()} />
            ) : (
              <Skeleton width="16px" height="12px" animate={false} radius={0} />
            )}
          </ActionIcon>
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <ScrollArea sx={{ height: '250px' }}>
          {options
            .filter(option => option.value)
            .map(option => (
              <Menu.Item
                key={option.value}
                icon={<ReactCountryFlag svg countryCode={option.value.toLowerCase()} />}
                onClick={() => onChange(option.value)}
              >
                {option.label}
              </Menu.Item>
            ))}
        </ScrollArea>
      </Menu.Dropdown>
    </Menu>
  );
};

type Props = RPNIProps<InputProps> & Pick<InputWrapperProps, 'required' | 'label' | 'error'>;

export const PhoneInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { sx, className, required, label, error, value, onChange, ...rest } = props;

  const labels = useLabels();

  return (
    <Input.Wrapper
      ref={ref}
      sx={{
        '.PhoneInput': {
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          '> div:last-child': {
            flex: 1,
            '> input': {
              paddingLeft: '40px',
            },
          },
        },
        ...sx,
      }}
      className={className}
      required={required}
      label={label}
      error={error}
    >
      <RPNIComponent
        {...rest}
        international
        labels={labels}
        invalid={!!error}
        value={value && value.length > 0 && !value.startsWith('+') ? `+${value}` : value}
        inputComponent={Input}
        countrySelectComponent={CountrySelect}
        onChange={phone => onChange(phone ?? undefined)}
      />
    </Input.Wrapper>
  );
});
