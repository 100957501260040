import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Order } from 'shared/lib/bazar-api';
import type { OrderProductFormValues } from '../types';

type AddOrderProductDTO = {
  orderId: Order['id'];
  data: OrderProductFormValues;
};

export const useAddOrderProduct = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: AddOrderProductDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, AddOrderProductDTO>({
    mutationFn: async variables => {
      console.log('add-order-product', variables);
      return null;
    },
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Товар добавлен',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['orders', { orderId: variables.orderId }] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
