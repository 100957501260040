import { useTranslation } from 'react-i18next';
import { Button, Group, Stack, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import { UserAddModal, UsersDataGrid } from 'features/users';
import { SearchInput, Suspense } from 'shared/components';
import { useSearch } from 'shared/lib/search';

export const UsersPage = () => {
  const [search, setSearch] = useSearch({ query: '' });

  const { t } = useTranslation('pages/users');
  const modals = useModals();

  const hasWritePermission = usePermission('users.write');

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('users')}</Title>
      <Group>
        <SearchInput
          sx={{ flex: 1 }}
          value={search.query}
          onChange={query => setSearch({ query })}
        />
        <Button
          disabled={!hasWritePermission}
          leftIcon={<PlusIcon size={14} />}
          onClick={() => {
            const modalId = modals.openModal({
              title: t('add-user'),
              children: (
                <Suspense>
                  <UserAddModal onClose={() => modals.closeModal(modalId)} />
                </Suspense>
              ),
            });
          }}
        >
          {t('add-user')}
        </Button>
      </Group>
      <UsersDataGrid query={search.query} actionsColumn={hasWritePermission} />
    </Stack>
  );
};
