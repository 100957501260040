import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Group, Image, Input, Text } from '@mantine/core';
import { Dropzone, DropzoneProps } from '@mantine/dropzone';
import { useMutation } from '@tanstack/react-query';
import { X as XIcon } from 'tabler-icons-react';
import { FileType, bazarApi } from 'shared/lib/bazar-api';

type Props = {
  type: FileType;
  required?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  error?: React.ReactNode;
  value?: string | null;
  onChange: (value?: string | null) => void;
} & Pick<DropzoneProps, 'accept' | 'maxSize'>;

export const FileDropzone = forwardRef<HTMLInputElement, Props>(
  ({ type, accept, maxSize, required, disabled, label, error, value, onChange }, ref) => {
    const { t } = useTranslation('shared', { keyPrefix: 'file-dropzone' });

    const { isLoading, mutate } = useMutation<{ url: string }, Error, File>({
      mutationFn: file => bazarApi.files.upload({ file, type }),
      onSuccess: result => onChange(result.url),
    });

    if (value) {
      return (
        <Input.Wrapper
          label={label}
          error={error}
          required={required}
          sx={{ position: 'relative' }}
        >
          <ActionIcon
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={() => onChange(null)}
          >
            <XIcon size={14} />
          </ActionIcon>
          <Image radius="md" src={value} alt="banner-image" height={150} fit="contain" />
        </Input.Wrapper>
      );
    }

    return (
      <Input.Wrapper ref={ref} label={label} error={error} required={required}>
        <Dropzone
          disabled={disabled}
          loading={isLoading}
          accept={accept}
          maxSize={maxSize}
          maxFiles={1}
          onDrop={files => {
            const [file] = files;
            mutate(file);
          }}
        >
          <Group position="center" spacing="xl" sx={{ height: 150, pointerEvents: 'none' }}>
            <Text size="sm" align="center">
              {t('drop-file-please')}
            </Text>
          </Group>
        </Dropzone>
      </Input.Wrapper>
    );
  },
);
