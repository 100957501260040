import { parseDate } from 'shared/utils/date';
import { bazarApiClient } from '../client';
import { Darkstore, PromoCode, PromoCodesCampaign } from '../types';

const getList = async ({
  darkstoreId,
  campaignId,
}: {
  darkstoreId: Darkstore['id'];
  campaignId?: PromoCodesCampaign['id'];
}): Promise<PromoCode[]> => {
  let data = [];
  if (campaignId) {
    const res = await bazarApiClient.get(`admin/promocodes-campaign/${darkstoreId}/${campaignId}`);
    data = await (await res.json<{ promocodes: any[] }>()).promocodes;
  } else {
    const res = await bazarApiClient.get(`admin/promocodes/${darkstoreId}`);
    data = await res.json<any[]>();
  }
  return data.map(item => ({
    id: item.id,
    darkstoreId,
    type: item.type,
    name: item.code,
    value: item.value,
    debitingLimit: item.maxVal,
    blacklist: item.blacklist.map((b: any) => ({
      street: b.street,
      house: b.building,
      flat: b.flat,
    })),
    forOneOrder: Boolean(item.isFirst),
    forFirstOrder: Boolean(item.isOnce),
    indefinite: Boolean(item.isIndefinite),
    statistic: {
      used: item.statistic,
      one: item.qty1,
      two: item.qty2,
      three: item.qty3,
      sum: item.statisticSum,
    },
    startedAt: parseDate(item.dateStart),
    endedAt: parseDate(item.dateFinish),
  }));
};

export const add = async (variables: Omit<PromoCode, 'id' | 'statistic'>) => {
  const body = JSON.stringify({
    darkstoreId: variables.darkstoreId,
    type: variables.type,
    code: variables.name,
    value: variables.value,
    maxVal: variables.debitingLimit,
    blacklist: variables.blacklist.map((b: any) => ({
      street: b.street,
      building: b.house,
      flat: b.flat,
    })),
    isFirst: variables.forOneOrder ? 1 : 0,
    isOnce: variables.forFirstOrder ? 1 : 0,
    isIndefinite: variables.indefinite ? 1 : 0,
    dateStart: variables.startedAt,
    dateFinish: variables.endedAt,
  });
  const res = await bazarApiClient.post('admin/promocodes', { body });
  const data = await res.json();
  return data;
};

export const edit = async (variables: Omit<PromoCode, 'statistic'>) => {
  const body = JSON.stringify({
    id: variables.id,
    darkstoreId: variables.darkstoreId,
    type: variables.type,
    code: variables.name,
    value: variables.value,
    maxVal: variables.debitingLimit,
    blacklist: variables.blacklist.map((b: any) => ({
      street: b.street,
      building: b.house,
      flat: b.flat,
    })),
    isFirst: variables.forOneOrder ? 1 : 0,
    isOnce: variables.forFirstOrder ? 1 : 0,
    isIndefinite: variables.indefinite ? 1 : 0,
    dateStart: variables.startedAt,
    dateFinish: variables.endedAt,
  });
  const res = await bazarApiClient.put('admin/promocodes', { body });
  const data = await res.json();
  return data;
};

const remove = async ({ id }: { id: PromoCode['id'] }) => {
  const res = await bazarApiClient.delete(`admin/promocodes/${id}`);
  const data = await res.json();
  return data;
};

const getCampaigns = async ({
  darkstoreId,
}: {
  darkstoreId: Darkstore['id'];
}): Promise<PromoCodesCampaign[]> => {
  const res = await bazarApiClient.get(`admin/promocodes-campaign/${darkstoreId}`);
  const data = await res.json<any[]>();
  return data.map(item => ({
    id: item.campaignId,
    name: item.campaign,
    promocodes: item.promocodes.map((p: any) => ({
      id: p.id,
      darkstoreId,
      type: p.type,
      name: p.code,
      value: p.value,
      debitingLimit: p.maxVal,
      blacklist: p.blacklist.map((b: any) => ({
        street: b.street,
        house: b.building,
        flat: b.flat,
      })),
      forOneOrder: Boolean(p.isFirst),
      forFirstOrder: Boolean(p.isOnce),
      indefinite: Boolean(p.isIndefinite),
      statistic: {
        used: p.statistic,
        one: p.qty1,
        two: p.qty2,
        three: p.qty3,
        sum: p.statisticSum,
      },
      startedAt: parseDate(p.dateStart),
      endedAt: parseDate(p.dateFinish),
    })),
    statistic: {
      used: item.statistic,
      one: item.qty1,
      two: item.qty2,
      three: item.qty3,
      sum: item.statisticSum,
    },
  }));
};

export const addCampaign = async (
  variables: Omit<PromoCode, 'id' | 'statistic'> & { body: string; amount: number },
) => {
  const body = JSON.stringify({
    darkstoreId: variables.darkstoreId,
    type: variables.type,
    campaign: variables.name,
    value: variables.value,
    maxVal: variables.debitingLimit,
    code: variables.body,
    count: variables.amount,
    blacklist: variables.blacklist.map((b: any) => ({
      street: b.street,
      building: b.house,
      flat: b.flat,
    })),
    isFirst: variables.forOneOrder ? 1 : 0,
    isOnce: variables.forFirstOrder ? 1 : 0,
    isIndefinite: variables.indefinite ? 1 : 0,
    dateStart: variables.startedAt,
    dateFinish: variables.endedAt,
  });
  const res = await bazarApiClient.post('admin/promocodes', { body });
  const data = await res.json();
  return data;
};

export const editCampaign = async (
  variables: Omit<PromoCode, 'statistic'> & { body: string; amount: number },
) => {
  const body = JSON.stringify({
    id: variables.id,
    darkstoreId: variables.darkstoreId,
    type: variables.type,
    campaign: variables.name,
    value: variables.value,
    maxVal: variables.debitingLimit,
    code: variables.body,
    count: variables.amount,
    blacklist: variables.blacklist.map((b: any) => ({
      street: b.street,
      building: b.house,
      flat: b.flat,
    })),
    isFirst: variables.forOneOrder ? 1 : 0,
    isOnce: variables.forFirstOrder ? 1 : 0,
    isIndefinite: variables.indefinite ? 1 : 0,
    dateStart: variables.startedAt,
    dateFinish: variables.endedAt,
  });
  const res = await bazarApiClient.put('admin/promocodes-campaign', { body });
  const data = await res.json();
  return data;
};

const removeCampaign = async ({ id }: { id: PromoCodesCampaign['id'] }) => {
  const res = await bazarApiClient.delete(`admin/promocodes-campaign/${id}`);
  const data = await res.json();
  return data;
};

export const promoCodes = {
  getList,
  add,
  edit,
  remove,
  getCampaigns,
  addCampaign,
  editCampaign,
  removeCampaign,
};
