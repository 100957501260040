import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useSuppliers = ({
  query,
  sortBy,
  sortOrder,
  pageNumber,
  pageSize,
}: {
  query: string;
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['suppliers', { darkstoreId, query, sortBy, sortOrder, pageNumber, pageSize }],
    queryFn: async () => {
      return bazarApi.suppliers.getList({
        darkstoreId,
        query,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
      });
    },
    keepPreviousData: true,
  });

  const { rows, totalPages } = data ?? { rows: [], totalPages: 0 };

  return { isFetching, suppliers: rows, totalPages };
};
