import { useTranslation } from 'react-i18next';
import { Select, Sx } from '@mantine/core';
import { EmployeeRole } from 'shared/lib/bazar-api';
import { EMPLOYEE_ROLES } from '../constants';

export const EmployeeRoleSelect = ({
  className,
  sx,
  required,
  label,
  value,
  error,
  onChange,
}: {
  className?: string;
  sx?: Sx;
  required?: boolean;
  label?: string;
  value?: EmployeeRole | null;
  error?: string;
  onChange?: (value: EmployeeRole) => void;
}) => {
  const { t } = useTranslation('features/employees', { keyPrefix: 'employee-role-select' });

  return (
    <Select
      className={className}
      sx={sx}
      required={required}
      label={label}
      placeholder={t('select-employee-role')}
      data={EMPLOYEE_ROLES}
      value={value}
      error={error}
      onChange={onChange}
    />
  );
};
