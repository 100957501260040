import microdiff from 'microdiff';

export const get = (obj: Record<string, any>, key: string) => {
  return key.split('.').reduce(
    //@ts-ignore
    (acc, key) => (acc && acc[key] !== null && acc[key] !== undefined ? acc[key] : null),
    obj,
  );
};

export const diff = <T extends Record<string, any>>(
  obj1: T,
  obj2: Record<string, any>,
): Partial<T> => {
  const changes: Partial<typeof obj1> = microdiff(obj1, obj2).reduce((acc, c) => {
    // TODO: think about it
    const key = c.path[0];
    if (typeof obj2[key] !== 'undefined') {
      return { ...acc, [key]: obj2[key] };
    }
    return acc;
  }, {});
  return changes;
};

export const equal = (obj1: Record<string, any>, obj2: Record<string, any>) => {
  return microdiff(obj1, obj2).length === 0;
};
