import { useTranslation } from 'react-i18next';
import { Group, SimpleGrid, Stack, Text } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Supply } from 'shared/lib/bazar-api';
import { Form, FormController } from 'shared/lib/form';
import { formatDate } from 'shared/utils/date';
import { supplyFormSchema } from '../schemas';
import { SupplyFormValues } from '../types';

export const SupplyForm = ({
  disabled,
  defaultValues,
  onSubmit,
}: {
  disabled?: boolean;
  defaultValues: Supply;
  onSubmit: (values: SupplyFormValues) => void;
}) => {
  const { t } = useTranslation('features/supplies', { keyPrefix: 'supply-form' });

  return (
    <Form
      disabled={disabled}
      defaultValues={defaultValues}
      validationSchema={supplyFormSchema}
      onSubmit={onSubmit}
    >
      {form => {
        const submit = form.handleSubmit(onSubmit, e => console.warn(e));
        return (
          <SimpleGrid cols={3} spacing={24}>
            <Stack>
              <Group grow>
                <Text size="sm" weight={500}>
                  {t('darkstore')}
                </Text>
                <Text size="sm">{defaultValues.darkstoreName}</Text>
              </Group>
              <Group grow>
                <Text size="sm" weight={500}>
                  {t('supplier')}
                </Text>
                <Text size="sm">{defaultValues.supplierName}</Text>
              </Group>
              <Group grow>
                <Text size="sm" weight={500}>
                  {t('consignment-note')}
                </Text>
                <Text size="sm">{defaultValues.consignmentNoteNumber}</Text>
              </Group>
            </Stack>
            <Stack>
              <Group grow>
                <Text size="sm" weight={500}>
                  {t('created-at')}
                </Text>
                <Text size="sm">{formatDate(defaultValues.createdAt, 'DD.MM.YYYY')}</Text>
              </Group>
              <Group grow>
                <Text size="sm" weight={500}>
                  {t('expected-at')}
                </Text>
                <FormController
                  name="expectedAt"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      {...field}
                      required
                      clearable={false}
                      error={fieldState?.error?.message}
                      onChange={expectedAt => {
                        field.onChange(expectedAt);
                        submit();
                      }}
                    />
                  )}
                />
              </Group>
            </Stack>
            <Stack>
              <Group grow>
                <Text size="sm" weight={500}>
                  {t('sum-without-vat')}
                </Text>
                <Text size="sm">{defaultValues.sumWithoutVat}</Text>
              </Group>
              <Group grow>
                <Text size="sm" weight={500}>
                  {t('vat')}
                </Text>
                <Text size="sm">{defaultValues.vat}</Text>
              </Group>
              <Group grow>
                <Text size="sm" weight={500}>
                  {t('sum-with-vat')}
                </Text>
                <Text size="sm">{defaultValues.sumWithVat}</Text>
              </Group>
            </Stack>
          </SimpleGrid>
        );
      }}
    </Form>
  );
};
