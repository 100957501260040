const IS_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development';
const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
const BAZAR_API_URL = process.env.REACT_APP_API_URL ?? 'https://api.bazar-store.by';
const FILESTORAGE_URL = process.env.REACT_APP_FILESTORAGE_URL ?? 'https://bazar-store.by';
const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBUvdPzDj-7eRMheZPltevn-4pl_wJNbok',
  authDomain: 'bazar-dev-null.firebaseapp.com',
  projectId: 'bazar-dev-null',
  storageBucket: 'bazar-dev-null.appspot.com',
  messagingSenderId: '657885968750',
  appId: '1:657885968750:web:6ebc56eb2858c17d3d297f',
  measurementId: 'G-KWQCP00W8G',
};
const GOOGLE_API_KEY = 'AIzaSyD8uEijT8Aryue-HNrGdgQxQfnTrVlEGvE';

export {
  IS_DEVELOPMENT_ENV,
  BASE_URL,
  BAZAR_API_URL,
  FILESTORAGE_URL,
  FIREBASE_CONFIG,
  GOOGLE_API_KEY,
};
