import { ReactNode, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';
import { AuthContext } from './auth-context';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(
    !!localStorage.getItem('token'),
  );

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: bazarApi.auth.getUser,
    suspense: true,
    enabled: !!isAuthenticated,
  });

  const signIn = (user: any) => {
    setIsAuthenticated(true);
    localStorage.setItem('token', user.token);
    queryClient.setQueryData(['user'], user);
  };

  const signOut = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    queryClient.removeQueries(['user']);
  };

  return (
    <AuthContext.Provider value={{ user: data, signIn, signOut }}>{children}</AuthContext.Provider>
  );
};
