import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Anchor,
  Breadcrumbs,
  Button,
  Card,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import {
  SupplyForm,
  SupplyProductAddModal,
  SupplyProductsDataGrid,
  SupplyStatusBadge,
  SupplyStatusMenu,
  useEditSupply,
  useSupply,
} from 'features/supplies';
import { Suspense } from 'shared/components';
import { SupplyStatus } from 'shared/lib/bazar-api';

export const SupplyEditPage = () => {
  const { supplyId } = useParams();

  const { t } = useTranslation('pages/supplies');
  const modals = useModals();

  const hasWritePermission = usePermission('supplies.write');

  const { isFetching, supply } = useSupply({ supplyId: Number(supplyId) });
  const { edit } = useEditSupply();

  if (isFetching) {
    return <LoadingOverlay visible />;
  }

  if (!supply) {
    return (
      <Stack>
        <Breadcrumbs>
          <Anchor component={Link} to="..">
            {t('supplies')}
          </Anchor>
          <Text>{t('supply-not-found')}</Text>
        </Breadcrumbs>
        <Title order={2}>{t('supply-not-found')}</Title>
      </Stack>
    );
  }

  const canEdit = hasWritePermission && supply.status !== SupplyStatus.Canceled;

  return (
    <Stack spacing="xl">
      <Breadcrumbs>
        <Anchor component={Link} to="..">
          {t('supplies')}
        </Anchor>
        <Text>
          {t('supply')} {supply.number}
        </Text>
      </Breadcrumbs>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Group position="apart">
            <Title order={2}>
              {t('supply')} {supply.number}
            </Title>
            <SupplyStatusMenu
              disabled={!canEdit}
              control={<SupplyStatusBadge status={supply.status} size="lg" />}
              onItemClick={item => edit({ supplyId: supply.id, data: { status: item.value } })}
            />
          </Group>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <SupplyForm
            disabled={!canEdit}
            defaultValues={supply}
            onSubmit={data => edit({ supplyId: supply.id, data })}
          />
        </Card.Section>
      </Card>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Group position="apart">
            <Title order={2}>{t('products')}</Title>
            <Button
              disabled={!canEdit}
              leftIcon={<PlusIcon size={14} />}
              onClick={() => {
                const modalId = modals.openModal({
                  title: t('add-product'),
                  children: (
                    <Suspense>
                      <SupplyProductAddModal
                        supplyId={supply.id}
                        darkstoreId={supply.darkstoreId}
                        supplierId={supply.supplierId}
                        onClose={() => modals.closeModal(modalId)}
                      />
                    </Suspense>
                  ),
                });
              }}
            >
              {t('add-product')}
            </Button>
          </Group>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <SupplyProductsDataGrid
            supplyId={supply.id}
            darkstoreId={supply.darkstoreId}
            supplierId={supply.supplierId}
            data={supply.products}
            actionsColumn={canEdit}
          />
        </Card.Section>
      </Card>
    </Stack>
  );
};
