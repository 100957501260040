import { useTranslation } from 'react-i18next';
import { ActionIcon, Button, Group, LoadingOverlay, Menu, Stack, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  DotsVertical as DotsVerticalIcon,
  GripVertical as GripVerticalIcon,
  Pencil as PencilIcon,
  Plus as PlusIcon,
  Trash as TrashIcon,
} from 'tabler-icons-react';
import { SortableList, Suspense } from 'shared/components';
import { Category, Subcategory } from 'shared/lib/bazar-api';
import { useReorderSubcategories, useSubcategories } from '../hooks';
import { SubcategoryAddModal } from './subcategory-add-modal';
import { SubcategoryEditModal } from './subcategory-edit-modal';
import { SubcategoryRemoveModal } from './subcategory-remove-modal';

export const SubcategoriesList = ({ categoryId }: { categoryId: Category['id'] }) => {
  const { t } = useTranslation('features/categories', { keyPrefix: 'subcategories-list' });

  const modals = useModals();

  const { isFetching, subcategories } = useSubcategories({ categoryId });
  const { isReordering, reorder } = useReorderSubcategories();

  return (
    <Stack sx={{ position: 'relative' }}>
      <LoadingOverlay visible={isFetching || isReordering} />
      <Button
        variant="outline"
        leftIcon={<PlusIcon size={14} />}
        onClick={() => {
          const modalId = modals.openModal({
            title: t('add-subcategory'),
            children: (
              <Suspense>
                <SubcategoryAddModal
                  categoryId={categoryId}
                  order={subcategories.length}
                  onAdd={() => modals.closeModal(modalId)}
                />
              </Suspense>
            ),
          });
        }}
      >
        {t('add-subcategory')}
      </Button>
      <Stack spacing={4}>
        <SortableList
          items={subcategories.map(s => s.id.toString())}
          onItemsChange={items => {
            const data: {
              id: Subcategory['id'];
              name: string;
              order: number;
            }[] = [];
            items.forEach((id, index) => {
              const subcategory = subcategories.find(o => o.id.toString() === id);
              if (subcategory) {
                data.push({ id: subcategory.id, name: subcategory.name, order: index });
              }
            });
            reorder({ data });
          }}
          renderItem={({ id, style, attributes, listeners, setNodeRef }) => {
            const subcategory = subcategories.find(s => s.id.toString() === id);
            if (!subcategory) {
              return null;
            }
            return (
              <Group ref={setNodeRef} style={style} spacing={16} position="apart" align="center">
                <Text
                  sx={{
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {subcategory.name}
                </Text>
                <Group spacing={8}>
                  <ActionIcon {...listeners} {...attributes}>
                    <GripVerticalIcon size={14} />
                  </ActionIcon>
                  <Menu position="bottom-end" width={160}>
                    <Menu.Target>
                      <ActionIcon>
                        <DotsVerticalIcon size={14} />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component="button"
                        icon={<PencilIcon size={14} />}
                        onClick={() => {
                          const modalId = modals.openModal({
                            title: t('edit-subcategory'),
                            children: (
                              <Suspense>
                                <SubcategoryEditModal
                                  subcategory={subcategory}
                                  onEdit={() => modals.closeModal(modalId)}
                                />
                              </Suspense>
                            ),
                          });
                        }}
                      >
                        {t('edit')}
                      </Menu.Item>
                      <Menu.Item
                        component="button"
                        icon={<TrashIcon size={14} />}
                        onClick={() => {
                          const modalId = modals.openModal({
                            title: t('remove-subcategory'),
                            children: (
                              <Suspense>
                                <SubcategoryRemoveModal
                                  subcategory={subcategory}
                                  onClose={() => modals.closeModal(modalId)}
                                />
                              </Suspense>
                            ),
                          });
                        }}
                      >
                        {t('remove')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Group>
              </Group>
            );
          }}
        />
      </Stack>
    </Stack>
  );
};
