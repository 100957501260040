import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect, MultiSelectProps, SelectItem, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';

type Props = Omit<MultiSelectProps, 'data' | 'value' | 'onChange'> & {
  value?: number[];
  onChange: (value: number[]) => void;
};

export const DaysMultiSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    const { t } = useTranslation('shared', { keyPrefix: 'days-select' });

    const theme = useMantineTheme();

    const data: SelectItem[] = useMemo(() => {
      return dayjs()
        .locale(theme.datesLocale)
        .localeData()
        .weekdays()
        .map((day, index) => ({
          label: day,
          value: index.toString(),
        }));
    }, [theme.datesLocale]);

    return (
      <MultiSelect
        ref={ref}
        placeholder={t('select-days')}
        data={data}
        value={value?.map(item => item.toString())}
        onChange={newValue => onChange(newValue.map(item => Number(item)))}
        {...rest}
      />
    );
  },
);
