import { useTranslation } from 'react-i18next';
import { Button, Paper, PasswordInput, Stack, TextInput, Title } from '@mantine/core';
import { z } from 'zod';
import { DeepPartial, Form, FormController } from 'shared/lib/form';

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

type SignInFormValues = z.infer<typeof schema>;

export const SignInForm = ({
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  isSubmitting: boolean;
  defaultValues: DeepPartial<SignInFormValues>;
  onSubmit: (values: SignInFormValues) => void;
}) => {
  const { t } = useTranslation('features/auth', { keyPrefix: 'sign-in-form' });

  return (
    <Paper p="xl" shadow="xs" radius="md" sx={{ flexBasis: 300 }}>
      <Form defaultValues={defaultValues} validationSchema={schema} onSubmit={onSubmit}>
        <Stack>
          <Title order={1}>{t('signing-in')}</Title>
          <FormController
            name="email"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                required
                type="email"
                autoComplete="email"
                label="Email"
                placeholder={t('input-email')}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormController
            name="password"
            render={({ field, fieldState }) => (
              <PasswordInput
                {...field}
                required
                autoComplete="current-password"
                label={t('password')}
                placeholder={t('input-password')}
                error={fieldState.error?.message}
              />
            )}
          />
          <Button type="submit" loading={isSubmitting}>
            {t('sign-in')}
          </Button>
        </Stack>
      </Form>
    </Paper>
  );
};
