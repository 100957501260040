import { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteItem, Loader, Sx } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { ProductRow } from 'shared/lib/bazar-api';
import { useSearchProducts } from '../hooks';

export const ProductAutocomplete = ({
  className,
  sx,
  required,
  label,
  value,
  error,
  onChange,
}: {
  className?: string;
  sx?: Sx;
  required?: boolean;
  label?: string;
  value?: string;
  error?: string;
  onChange: (item: Pick<ProductRow, 'id' | 'name' | 'amount'>) => void;
}) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 300);
  const { isFetching, products } = useSearchProducts({ query: debouncedQuery });

  useEffect(() => setQuery(value ?? ''), [value]);

  const handleItemSubmit = (item: AutocompleteItem) => {
    const product = products.find(a => a.name === item.value);
    if (product) {
      setQuery(product.name);
      onChange(product);
    }
  };

  return (
    <Autocomplete
      className={className}
      sx={sx}
      required={required}
      label={label}
      placeholder="Например, молоко"
      data={products.map(p => ({ value: p.name }))}
      value={query}
      error={error}
      filter={() => true}
      rightSection={isFetching && <Loader size="xs" />}
      onChange={setQuery}
      onBlur={() => setQuery(value ?? '')}
      onItemSubmit={handleItemSubmit}
    />
  );
};
