import { parseDate } from 'shared/utils/date';
import { bazarApiClient } from '../client';
import { Mailing, MailingAvailability } from '../types';

const getList = async ({ partnerId }: { partnerId: number }): Promise<Mailing[]> => {
  const res = await bazarApiClient.get('admin/mailing', { searchParams: { partnerId } });
  const data = await res.json<any[]>();
  return data.map(item => {
    item.settings.availability =
      item.settings.isAllProducts !== null
        ? item.settings.isAllProducts
          ? MailingAvailability.All
          : MailingAvailability.OneOf
        : null;
    delete item.settings.isAllProducts;
    item.createdAt = parseDate(item.date);
    delete item.date;
    return item;
  });
};

const add = async (variables: Omit<Mailing, 'id' | 'createdAt'>) => {
  const body = JSON.stringify({
    ...variables,
    settings: {
      ...variables.settings,
      availability: undefined,
      isAllProducts: variables.settings.availability
        ? variables.settings.availability === MailingAvailability.All
        : null,
    },
  });
  const res = await bazarApiClient.post('admin/mailing', { body });
  const data = await res.json();
  return data;
};

const edit = async (variables: Omit<Mailing, 'createdAt'>) => {
  const body = JSON.stringify({
    ...variables,
    settings: {
      ...variables.settings,
      availability: undefined,
      isAllProducts:
        variables.settings.availability !== null
          ? variables.settings.availability === MailingAvailability.All
          : null,
    },
  });
  const res = await bazarApiClient.put('admin/mailing', { body });
  const data = await res.json();
  return data;
};

const remove = async ({ id }: { id: Mailing['id'] }) => {
  const res = await bazarApiClient.delete(`admin/mailing/${id}`);
  const data = await res.json();
  return data;
};

const send = async ({ id }: { id: Mailing['id'] }) => {
  const res = await bazarApiClient.get(`admin/mailing/${id}`);
  const data = await res.json();
  return data;
};

export const mailings = { getList, add, edit, remove, send };
