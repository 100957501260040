import { useSettings } from 'features/settings';
import { useAddCategory } from '../hooks';
import { CategoryForm } from './category-form';

export const CategoryAddModal = ({ onAdd }: { onAdd: () => void }) => {
  const { isAdding, add } = useAddCategory({ onSuccess: onAdd });

  const partnerId = useSettings(settings => settings.partnerId);

  return (
    <CategoryForm
      isSubmitting={isAdding}
      defaultValues={{
        partnerId,
        name: '',
        image: '',
        icon: '',
        order: 0,
        promoted: false,
      }}
      onSubmit={data => add({ data })}
    />
  );
};
