import { bazarApiClient } from '../client';
import { User } from '../types';

const getList = async ({
  partnerId,
  query,
}: {
  partnerId: number;
  query: string;
}): Promise<User[]> => {
  const res = await bazarApiClient.get('admin/users', { searchParams: { partnerId, query } });
  const data = await res.json<any[]>();
  return data.map(item => ({ ...item, password: item.password ?? null }));
};

const add = async (variables: Omit<User, 'id' | 'providerId'>) => {
  const body = JSON.stringify({
    ...variables,
    phone: '',
    patronymic: '',
  });
  const res = await bazarApiClient.post('admin/users', { body });
  const data = await res.json();
  return data;
};

const edit = async (variables: Omit<User, 'providerId'>) => {
  const body = JSON.stringify(variables);
  const res = await bazarApiClient.patch('admin/users', { body });
  const data = await res.json();
  return data;
};

const remove = async ({ id }: { id: User['id'] }) => {
  const res = await bazarApiClient.delete(`admin/users/${id}`);
  const data = await res.json();
  return data;
};

const editPermissions = async (variables: Pick<User, 'id' | 'email' | 'acl'>) => {
  const body = JSON.stringify({
    userId: variables.id,
    userEmail: variables.email,
    acl: variables.acl,
  });
  const res = await bazarApiClient.patch('admin/users/acl', { body });
  const data = await res.json();
  return data;
};

export const users = { getList, add, edit, remove, editPermissions };
