import { useAuth } from 'features/auth';
import { useOnMount } from 'shared/hooks';
import { useSettings } from './use-settings';

export const SettingsInitializer = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();

  const init = useSettings(settings => settings.init);

  useOnMount(() => {
    if (auth.user) {
      init({ partnerId: auth.user.partnerId, darkstoreId: auth.user.darkstores[0] });
    }
  });

  return children;
};
