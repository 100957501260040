import { useSettings } from 'features/settings';
import { Category } from 'shared/lib/bazar-api';
import { useEditCategory } from '../hooks';
import { CategoryForm } from './category-form';

export const CategoryEditModal = ({
  category,
  onEdit,
}: {
  category: Category;
  onEdit: () => void;
}) => {
  const { isEditing, edit } = useEditCategory({ onSuccess: onEdit });

  const partnerId = useSettings(settings => settings.partnerId);

  return (
    <CategoryForm
      isSubmitting={isEditing}
      defaultValues={{ partnerId, ...category }}
      onSubmit={data => edit({ categoryId: category.id, data })}
    />
  );
};
