import { forwardRef } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { DEFAULT_DELIVERY_TIMES } from '../../constants';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: number | null;
  onChange: (value: number) => void;
};

export const DefaultDeliveryTimeSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    return (
      <Select
        ref={ref}
        data={DEFAULT_DELIVERY_TIMES.map(t => ({ label: t.label, value: t.value.toString() }))}
        value={value?.toString()}
        onChange={newValue => onChange(Number(newValue))}
        {...rest}
      />
    );
  },
);
