import { ReactElement } from 'react';
import { Menu, UnstyledButton } from '@mantine/core';
import { SUPPLY_STATUSES } from '../constants';

export const SupplyStatusMenu = ({
  disabled,
  control,
  onItemClick,
}: {
  disabled?: boolean;
  control: ReactElement;
  onItemClick: (item: typeof SUPPLY_STATUSES[0]) => void;
}) => {
  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <UnstyledButton
          disabled={disabled}
          sx={{
            '&:hover *': {
              cursor: 'pointer',
            },
          }}
        >
          {control}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        {SUPPLY_STATUSES.map(s => (
          <Menu.Item key={s.value} onClick={() => onItemClick(s)}>
            {s.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
