import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';
import type { SupplierFormValues } from '../types';

type EditSupplierDTO = {
  supplierId: number;
  data: SupplierFormValues;
};

export const useEditSupplier = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, EditSupplierDTO>({
    mutationFn: variables => {
      return bazarApi.suppliers.edit({
        id: variables.supplierId,
        ...variables.data,
      });
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
    },
  });

  return { isEditing: isLoading, edit: mutate };
};
