import { useQuery } from '@tanstack/react-query';
import { bazarApi, Order } from 'shared/lib/bazar-api';

export const useOrder = ({ orderId }: { orderId?: Order['id'] }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['orders', { orderId }],
    queryFn: () => {
      if (orderId) {
        return bazarApi.orders.getOne({ id: orderId });
      }
    },
    enabled: !!orderId,
    suspense: true,
  });

  return { isFetching, order: data };
};
