import { useTranslation } from 'react-i18next';
import { Anchor, Col, Grid } from '@mantine/core';
import { CategorySelect } from 'features/categories';
import { FormController, FormWatch, useFormFieldArray } from 'shared/lib/form';
import type { ProductFormValues } from '../../types';
import { ProductSubcategories } from './product-subcategories';

export const ProductCategories = () => {
  const { t } = useTranslation('features/products', { keyPrefix: 'product-form' });

  const { fields, append, remove } = useFormFieldArray<ProductFormValues>({ name: 'categories' });

  console.log(fields);

  return (
    <>
      {fields.map((field, index) => (
        <Grid key={field.id}>
          <Col span={4} sx={{ position: 'relative' }}>
            <FormController
              name={`categories.${index}.id`}
              render={({ field, fieldState }) => (
                <CategorySelect
                  {...field}
                  required
                  label={t('category')}
                  error={fieldState.error?.message}
                />
              )}
            />
            {!field.disabled && (
              <>
                {index === 0 ? (
                  <Anchor
                    sx={{ position: 'absolute', top: 12, right: 8 }}
                    size="xs"
                    //@ts-ignore
                    onClick={() => append({ id: undefined, subcategories: [{ id: undefined }] })}
                  >
                    {t('add-category')}
                  </Anchor>
                ) : (
                  <Anchor
                    sx={{ position: 'absolute', top: 12, right: 8 }}
                    size="xs"
                    onClick={() => remove(index)}
                  >
                    {t('remove-category')}
                  </Anchor>
                )}
              </>
            )}
          </Col>
          <FormWatch
            name={`categories.${index}.id`}
            render={categoryId => (
              <ProductSubcategories categoryId={categoryId} categoryIndex={index} />
            )}
          />
        </Grid>
      ))}
    </>
  );
};
