import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type SettingsStore = {
  partnerId: number;
  darkstoreId: number;
  init: ({ partnerId, darkstoreId }: { partnerId: number; darkstoreId: number }) => void;
  setPartnerId: (partnerId: number) => void;
  setDarkstoreId: (darkstoreId: number) => void;
};

export const useSettings = create<SettingsStore>()(
  devtools(
    persist(
      set => ({
        partnerId: -1,
        darkstoreId: -1,
        init: ({ partnerId, darkstoreId }: { partnerId: number; darkstoreId: number }) => {
          set({ partnerId, darkstoreId });
        },
        setPartnerId: (partnerId: number) => set({ partnerId }),
        setDarkstoreId: (darkstoreId: number) => set({ darkstoreId }),
      }),
      { name: 'settings', getStorage: () => localStorage },
    ),
    { name: 'settings' },
  ),
);
