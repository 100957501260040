import { forwardRef } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { SupplierType } from 'shared/lib/bazar-api';
import { SUPPLIER_TYPES } from '../../constants';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: SupplierType | null;
  onChange: (value: SupplierType) => void;
};

export const SupplierTypeSelect = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <Select {...ref} data={SUPPLIER_TYPES} {...props} />;
});
