import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import {
  Check as CheckIcon,
  X as XIcon,
  Scale as ScaleIcon,
  Box as BoxIcon,
} from 'tabler-icons-react';
import { SupplierProduct } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid, DataGridProps } from 'shared/lib/data-grid';

type Props = {
  isLoading: boolean;
  data: SupplierProduct[];
} & Pick<DataGridProps<SupplierProduct>, 'onCellClick' | 'onCellEditStop'>;

export const ProductsDataGrid = ({ isLoading, data, onCellClick, onCellEditStop }: Props) => {
  const { t } = useTranslation('features/supplies-generate', { keyPrefix: 'products-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SupplierProduct>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`/products/${value}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('name', { size: 300, header: () => t('columns.1') }),
      columnHelper.accessor(row => row.request, {
        id: 'request',
        size: 100,
        meta: { editable: true },
        header: () => t('columns.2'),
      }),
      columnHelper.accessor('amount', { size: 100, header: () => t('columns.3') }),
      columnHelper.accessor('sold', { size: 100, header: () => t('columns.4') }),
      columnHelper.accessor('writeOff', { size: 100, header: () => t('columns.5') }),
      columnHelper.accessor('enabled', {
        size: 75,
        header: () => t('columns.6'),
        cell: params => {
          return params.getValue() ? (
            <CheckIcon size={14} color="green" />
          ) : (
            <XIcon size={14} color="red" />
          );
        },
      }),
      columnHelper.accessor('weighted', {
        size: 75,
        header: () => t('columns.7'),
        cell: params => {
          return params.getValue() ? (
            <ScaleIcon size={14} color="grey" />
          ) : (
            <BoxIcon size={14} color="grey" />
          );
        },
      }),
      columnHelper.accessor('minRequest', { size: 100, header: () => t('columns.8') }),
      columnHelper.accessor('barcode', { size: 150, header: () => t('columns.9') }),
      columnHelper.accessor('weight', { size: 100, header: () => t('columns.10') }),
      columnHelper.accessor('priceWithoutVat', { size: 150, header: () => t('columns.11') }),
    ];
  }, [t]);

  return (
    <DataGrid
      loading={isLoading}
      columns={columns}
      data={data}
      sorting
      sx={{ minHeight: '500px' }}
      getRowId={row => row.id.toString()}
      onCellClick={onCellClick}
      onCellEditStop={onCellEditStop}
    />
  );
};
