import { useQuery } from '@tanstack/react-query';
import { bazarApi, Category } from 'shared/lib/bazar-api';

export const useProductsWithOrder = ({ categoryId }: { categoryId: Category['id'] }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['products-with-order', { categoryId }],
    queryFn: () => {
      return bazarApi.assortment.getProductsWithOrder({ categoryId });
    },
  });

  return { isFetching, products: data ?? [] };
};
