import { useTranslation } from 'react-i18next';
import { Stack, Title } from '@mantine/core';
import { RegistryDataGrid, RegistryFiltersForm, useRegistry } from 'features/registry';
import { Category, Subcategory, Supplier } from 'shared/lib/bazar-api';
import { useSearch } from 'shared/lib/search';
import { equal } from 'shared/utils/object';

export const RegistryPage = () => {
  const [search, setSearch] = useSearch<{
    query: string;
    categoryId?: Category['id'];
    subcategoryId?: Subcategory['id'];
    supplierId?: Supplier['id'];
  }>({
    query: '',
    categoryId: undefined,
    subcategoryId: undefined,
    supplierId: undefined,
  });

  const { t } = useTranslation('pages/registry');

  const { isFetching, registry, refetch } = useRegistry(search);

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('registry')}</Title>
      <RegistryFiltersForm
        defaultValues={search}
        onSubmit={data => {
          const isEqual = equal(search, data);
          if (isEqual) {
            refetch();
          } else {
            setSearch(data);
          }
        }}
      />
      <RegistryDataGrid isLoading={isFetching} data={registry} />
    </Stack>
  );
};
