import { useTranslation } from 'react-i18next';
import { Button, Stack, PasswordInput, TextInput } from '@mantine/core';
import { z } from 'zod';
import { DarkstoreMultiSelect } from 'features/darkstores';
import { Form, FormController } from 'shared/lib/form';
import { userFormSchema } from '../schemas';
import type { UserFormValues } from '../types';
import { UserRoleSelect } from './user-role-select';

export const UserForm = ({
  mode,
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  mode: 'add' | 'edit';
  isSubmitting: boolean;
  defaultValues: Partial<UserFormValues>;
  onSubmit: (values: UserFormValues) => void;
}) => {
  const { t } = useTranslation('features/users', { keyPrefix: 'user-form' });

  const validationSchema =
    mode === 'add' ? userFormSchema.extend({ password: z.string().min(6) }) : userFormSchema;

  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Stack align="stretch">
        <FormController
          name="email"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              required
              label={t('email')}
              placeholder={t('input-email')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="name"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              label={t('name')}
              placeholder={t('input-name')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="surname"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              label={t('surname')}
              placeholder={t('input-surname')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="password"
          render={({ field, fieldState }) => (
            <PasswordInput
              {...field}
              required={mode === 'add'}
              autoComplete="new-password"
              label={t('password')}
              placeholder={t('input-password')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="role"
          render={({ field, fieldState }) => (
            <UserRoleSelect
              required
              label={t('role')}
              value={field.value}
              error={fieldState.error?.message}
              onChange={field.onChange}
            />
          )}
        />
        <FormController
          name="darkstores"
          render={({ field, fieldState }) => (
            <DarkstoreMultiSelect
              {...field}
              label={t('darkstores')}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t('save')}
        </Button>
      </Stack>
    </Form>
  );
};
