import { Badge, MantineSize } from '@mantine/core';
import { EmployeeStatus } from 'shared/lib/bazar-api';
import { EMPLOYEE_STATUSES_MAP } from '../constants';

const COLORS_MAP = {
  [EmployeeStatus.Outside]: 'gray',
  [EmployeeStatus.Working]: 'green',
  [EmployeeStatus.Sick]: 'yellow',
  [EmployeeStatus.Vacation]: 'blue',
  [EmployeeStatus.Dismissed]: 'red',
};

export const EmployeeStatusBadge = ({
  status,
  size,
}: {
  status: EmployeeStatus;
  size?: MantineSize;
}) => {
  return (
    <Badge color={COLORS_MAP[status]} size={size}>
      {EMPLOYEE_STATUSES_MAP[status]}
    </Badge>
  );
};
