import { useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@mantine/core';
import { useSupplierProducts } from 'features/suppliers';
import { FiltersForm, ProductsDataGrid, useGenerateSupplies } from 'features/supplies-generate';
import { useSearch } from 'shared/lib/search';
import { formatDate, parseDate } from 'shared/utils/date';
import { equal } from 'shared/utils/object';

export const SuppliesGeneratePage = () => {
  const [search, setSearch] = useSearch<{
    darkstoreId?: number;
    supplierId?: number;
    date: string;
  }>({
    darkstoreId: undefined,
    supplierId: undefined,
    date: formatDate(new Date(), 'YYYY-MM-DD'),
  });

  const { t } = useTranslation('pages/supplies');

  const filters = { ...search, date: parseDate(search.date) };

  const { isFetching, products, refetch, updateProductRequest } = useSupplierProducts({
    darkstoreId: filters.darkstoreId,
    supplierId: filters.supplierId,
  });
  const { isGenerating, supplies, generate } = useGenerateSupplies();

  return (
    <Stack>
      <FiltersForm
        defaultValues={filters}
        onSubmit={data => {
          const isEqual = equal(filters, data);
          if (isEqual) {
            refetch();
          } else {
            setSearch({ ...data, date: formatDate(data.date, 'YYYY-MM-DD') });
          }
        }}
      />
      <ProductsDataGrid
        isLoading={isFetching}
        data={products}
        onCellClick={({ rowId, columnId, value }) => {
          if (columnId === 'minRequest') {
            updateProductRequest({ id: Number(rowId), value: Number(value) });
          }
        }}
        onCellEditStop={({ rowId, columnId, value }) => {
          if (columnId === 'request') {
            updateProductRequest({ id: Number(rowId), value: Number(value) });
          }
        }}
      />
      <Button
        loading={isGenerating}
        disabled={Object.values(filters).some(v => !v)}
        sx={{ display: 'block', marginLeft: 'auto' }}
        onClick={() => {
          if (filters.darkstoreId && filters.supplierId) {
            generate({
              darkstoreId: filters.darkstoreId,
              supplierId: filters.supplierId,
              expectedAt: filters.date,
              products,
            });
          }
        }}
      >
        {t('generate')}
      </Button>
      <Box>{JSON.stringify(supplies)}</Box>
    </Stack>
  );
};
