import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { Check as CheckIcon, X as XIcon } from 'tabler-icons-react';
import { Category, ProductRow } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid, useDataGridState } from 'shared/lib/data-grid';
import { useCategoryProducts } from '../hooks';

export const ProductsDataGrid = ({ categoryId }: { categoryId: Category['id'] }) => {
  const { t } = useTranslation('features/products', { keyPrefix: 'products-data-grid' });

  // TODO: update columns
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ProductRow>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`${value}?categoryId=${categoryId}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('enabled', {
        size: 75,
        header: () => t('columns.1'),
        cell: params => {
          return params.getValue() ? (
            <CheckIcon size={14} color="green" />
          ) : (
            <XIcon size={14} color="red" />
          );
        },
      }),
      columnHelper.accessor('name', { size: 250, header: () => t('columns.2') }),
      columnHelper.accessor('price', { size: 100, header: () => t('columns.3') }),
      columnHelper.accessor('discount', { size: 100, header: () => t('columns.4') }),
      columnHelper.accessor('discountedPrice', { size: 150, header: () => t('columns.5') }),
      columnHelper.accessor('extraCharge', { size: 100, header: () => t('columns.6') }),
      columnHelper.accessor('amount', { size: 120, header: () => t('columns.7') }),
      columnHelper.accessor('barcode', { size: 150, header: () => t('columns.8') }),
      columnHelper.accessor('supplierName', { size: 150, header: () => t('columns.9') }),
      columnHelper.accessor('subcategoriesNames', { size: 150, header: () => t('columns.10') }),
    ];
  }, [categoryId, t]);

  const [state, setState] = useDataGridState({
    sorting: [{ id: 'name', desc: false }],
    pagination: { pageIndex: 0, pageSize: 30 },
  });

  const { isFetching, products, totalPages } = useCategoryProducts({ categoryId, ...state.server });

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={products}
      sorting
      manualSorting
      pagination
      manualPagination
      pageCount={totalPages}
      state={state}
      onStateChange={setState}
    />
  );
};
