export const InformationIcon = ({
  size = 24,
  color = 'currentColor',
  ...restProps
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.25 9.766a.367.367 0 0 0-.367-.367h-3.197a.837.837 0 0 1-.837-.836V5.367A.367.367 0 0 0 19.483 5H8.392a.642.642 0 0 0-.642.642v20.716c0 .355.287.642.642.642h15.216a.642.642 0 0 0 .642-.642V9.766Zm-14.301 3.76c0-.456.37-.826.825-.826h10.45a.825.825 0 1 1 0 1.65h-10.45a.825.825 0 0 1-.825-.825ZM10.774 16a.825.825 0 1 0 0 1.65h10.45a.825.825 0 0 0 0-1.65h-10.45Zm-.825 4.125c0-.456.37-.825.825-.825h10.45a.825.825 0 0 1 0 1.65h-10.45a.825.825 0 0 1-.825-.825Z"
      />
      <path d="M23.364 8.3h-1.97a.445.445 0 0 1-.445-.445v-1.97c0-.326.395-.49.626-.26l2.048 2.05a.367.367 0 0 1-.26.625Z" />
    </svg>
  );
};
