import { BlacklistAddress } from 'shared/lib/bazar-api';
import { useEditBlacklistAddress } from '../hooks';
import { BlacklistAddressForm } from './blacklist-address-form';

export const BlacklistAddressEditModal = ({
  address,
  onClose,
}: {
  address: BlacklistAddress;
  onClose: () => void;
}) => {
  const { isEditing, edit } = useEditBlacklistAddress({ onSuccess: onClose });

  return (
    <BlacklistAddressForm
      isSubmitting={isEditing}
      defaultValues={address}
      onSubmit={data => edit({ addressId: address.id, data })}
    />
  );
};
