import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, TextInput, TextInputProps } from '@mantine/core';
import { Search as SearchIcon, X as XIcon } from 'tabler-icons-react';

type Props = Omit<TextInputProps, 'onChange'> & {
  onChange: (value: string) => void;
};

export const SearchInput = forwardRef<HTMLInputElement, Props>(({ onChange, ...rest }, ref) => {
  const { t } = useTranslation('shared', { keyPrefix: 'search-input' });

  return (
    <TextInput
      ref={ref}
      placeholder={t('search')}
      icon={<SearchIcon size={14} />}
      rightSection={
        <ActionIcon onClick={() => onChange('')}>
          <XIcon size={14} />
        </ActionIcon>
      }
      onChange={e => onChange(e.target.value)}
      {...rest}
    />
  );
});
