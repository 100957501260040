import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi, Employee } from 'shared/lib/bazar-api';

export const useOrders = ({
  from,
  to,
  query,
  courierId,
  sortBy,
  sortOrder,
  pageNumber,
  pageSize,
}: {
  from: Date;
  to: Date;
  query: string;
  courierId?: Employee['id'];
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: [
      'orders',
      { darkstoreId, from, to, query, courierId, sortBy, sortOrder, pageNumber, pageSize },
    ],
    queryFn: () => {
      return bazarApi.orders.getList({
        darkstoreId,
        from,
        to,
        query,
        courierId,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
      });
    },
    keepPreviousData: true,
  });

  const { rows, totalPages } = data ?? { rows: [], totalPages: 0 };

  return { isFetching, orders: rows, totalPages };
};
