import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useBlacklistCustomers = ({ query }: { query: string }) => {
  const partnerId = useSettings(settings => settings.partnerId);

  const { isFetching, data } = useQuery({
    queryKey: ['blacklist-customers', { partnerId, query }],
    queryFn: async () => {
      return bazarApi.blacklist.getCustomers({ partnerId, query });
    },
  });

  return { isFetching, customers: data ?? [] };
};
