import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Avatar, Menu } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import {
  DotsVertical as DotsVerticalIcon,
  Trash as TrashIcon,
  Copy as CopyIcon,
} from 'tabler-icons-react';
import type { Employee, EmployeeRole } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid, useDataGridState } from 'shared/lib/data-grid';
import { formatPhone } from 'shared/utils/phone';
import { EMPLOYEE_ROLES_MAP } from '../constants';
import { useEmployees } from '../hooks';
import { EmployeeAddModal } from './employee-add-modal';
import { EmployeeRemoveModal } from './employee-remove-modal';
import { EmployeeStatusBadge } from './employee-status-badge';

export const EmployeesDataGrid = ({
  employeeRole,
  query,
  actionsColumn,
}: {
  employeeRole: EmployeeRole;
  query: string;
  actionsColumn?: boolean;
}) => {
  const { t } = useTranslation('features/employees', { keyPrefix: 'employees-data-grid' });

  const modals = useModals();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Employee>();
    const dataColumns = [
      columnHelper.accessor('id', {
        size: 100,
        // meta: { sticky: true },
        header: () => t('columns.0'),
      }),
      columnHelper.accessor('photo', {
        size: 75,
        header: () => t('columns.1'),
        cell: params => <Avatar src={params.getValue()} size="sm" radius="xl" />,
      }),
      columnHelper.accessor('name', { size: 175, header: () => t('columns.2') }),
      columnHelper.accessor('surname', { size: 175, header: () => t('columns.3') }),
      columnHelper.accessor('role', {
        size: 150,
        header: () => t('columns.4'),
        cell: params => EMPLOYEE_ROLES_MAP[params.getValue()],
      }),
      columnHelper.accessor('status', {
        size: 150,
        header: () => t('columns.5'),
        cell: params => <EmployeeStatusBadge status={params.getValue()} />,
      }),
      columnHelper.accessor('phone', {
        size: 175,
        header: () => t('columns.6'),
        cell: params => formatPhone(params.getValue()),
      }),
    ];
    if (actionsColumn) {
      return [
        columnHelper.display({
          id: 'actions',
          size: 50,
          meta: { sticky: true },
          cell: params => {
            return (
              <Menu position="bottom-start">
                <Menu.Target>
                  <ActionIcon>
                    <DotsVerticalIcon size={14} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component="button"
                    icon={<CopyIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('copy-employee'),
                        children: (
                          <Suspense>
                            <EmployeeAddModal
                              employee={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('copy')}
                  </Menu.Item>
                  <Menu.Item
                    component="button"
                    icon={<TrashIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('remove-employee'),
                        children: (
                          <Suspense>
                            <EmployeeRemoveModal
                              employee={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('remove')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );
          },
        }),
        ...dataColumns,
      ];
    }
    return dataColumns;
  }, [actionsColumn, t, modals]);

  const [debouncedQuery] = useDebouncedValue(query, 300);

  const [state, setState] = useDataGridState({
    sorting: [{ id: 'id', desc: false }],
    pagination: { pageIndex: 0, pageSize: 30 },
  });

  const { isFetching, employees, totalPages } = useEmployees({
    role: employeeRole,
    query: debouncedQuery,
    ...state.server,
  });

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={employees}
      sorting
      manualSorting
      pagination
      manualPagination
      pageCount={totalPages}
      state={state}
      onStateChange={setState}
    />
  );
};
