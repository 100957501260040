import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { OrderStatusBadge, PAYMENT_METHODS_MAP } from 'features/orders';
import type { CustomerOrder } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { formatDate } from 'shared/utils/date';

export const CustomerOrdersDataGrid = ({ data }: { data: CustomerOrder[] }) => {
  const { t } = useTranslation('features/customers', { keyPrefix: 'customer-orders-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<CustomerOrder>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`/orders/${value}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('status', {
        size: 150,
        header: () => t('columns.1'),
        cell: params => <OrderStatusBadge status={params.getValue()} />,
      }),
      columnHelper.accessor('paymentMethod', {
        size: 150,
        header: () => t('columns.2'),
        cell: params => PAYMENT_METHODS_MAP[params.getValue()],
      }),
      columnHelper.accessor('sum', {
        size: 75,
        header: () => t('columns.3'),
      }),
      columnHelper.accessor(
        row => {
          return `${row.courierName ?? ''} ${row.courierSurname ?? ''}`.trim();
        },
        {
          id: 'courier-info',
          size: 250,
          header: () => t('columns.4'),
        },
      ),
      columnHelper.accessor('createdAt', {
        size: 200,
        header: () => t('columns.5'),
        cell: params => formatDate(params.getValue()),
      }),
    ];
  }, [t]);

  return <DataGrid columns={columns} data={data} sorting sx={{ minHeight: '500px' }} />;
};
