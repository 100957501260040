export const parseSearch = <T extends Record<string, any>>(searchStr: string): T => {
  let searchObj = Object.fromEntries(new URLSearchParams(searchStr).entries());
  for (let key in searchObj) {
    const value = searchObj[key];
    try {
      searchObj[key] = JSON.parse(value);
    } catch (err) {
      // silent
    }
  }
  return searchObj as T;
};

export const stringifySearch = <T extends Record<string, any>>(search: T): string => {
  search = { ...search };
  if (search) {
    Object.keys(search).forEach(key => {
      const val = search[key];
      if (typeof val === 'undefined' || val === undefined) {
        delete search[key];
      } else if (val && typeof val === 'object' && val !== null) {
        try {
          //@ts-ignore
          search[key] = JSON.stringify(val);
        } catch (err) {
          // silent
        }
      }
    });
  }
  const searchStr = new URLSearchParams(search).toString();
  return searchStr ? `?${searchStr}` : '';
};
