import { PaymentTermType, SupplierType } from 'shared/lib/bazar-api';

export const SUPPLIER_TYPES = [
  { label: 'Импортер', value: SupplierType.Importer },
  { label: 'Дистрибьютор', value: SupplierType.Distributor },
  { label: 'Производитель', value: SupplierType.Manufacturer },
];

export const PAYMENT_TERM_TYPES = [
  { label: 'Предоплата', value: PaymentTermType.Prepayment },
  { label: 'Отсрочка платежа', value: PaymentTermType.Postponement },
];
