import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Grid,
  Group,
  Image,
  NumberInput,
  Switch,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { SupplierSelect } from 'features/suppliers';
import { DeepPartial, Form, FormController, FormWatch } from 'shared/lib/form';
import { round } from 'shared/utils/number';
import { productFormSchema } from '../../schemas';
import { ProductFormValues } from '../../types';
import { ProductAvailability } from './product-availability';
import { ProductCategories } from './product-categories';
import { ProductDiscount } from './product-discount';
import { ProductDiscountedPrice } from './product-discounted-price';

export const ProductForm = ({
  title,
  disabled,
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  title: string;
  disabled?: boolean;
  isSubmitting: boolean;
  defaultValues: DeepPartial<ProductFormValues>;
  onSubmit: (values: ProductFormValues) => void;
}) => {
  const { t } = useTranslation('features/products', { keyPrefix: 'product-form' });

  return (
    <Form
      disabled={disabled}
      defaultValues={defaultValues}
      validationSchema={productFormSchema}
      onSubmit={onSubmit}
    >
      {form => (
        <Card shadow="sm" radius="sm">
          <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
            <Group position="apart">
              <Title order={2}>{title}</Title>
              <Group>
                <Box>
                  <FormController
                    name="enabled"
                    render={({ field }) => (
                      <Switch {...field} label={t('enabled')} checked={field.value} />
                    )}
                  />
                </Box>
                <Box>
                  <FormController
                    name="liquidating"
                    render={({ field }) => (
                      <Switch {...field} label={t('liquidating')} checked={field.value} />
                    )}
                  />
                </Box>
                <Box>
                  <FormController
                    name="splitting"
                    render={({ field }) => (
                      <Switch {...field} label={t('splitting')} checked={field.value} />
                    )}
                  />
                </Box>
                <Box>
                  <FormController
                    name="weighted"
                    render={({ field }) => (
                      <Switch
                        {...field}
                        label={t('weighted')}
                        checked={field.value}
                        onChange={e => {
                          const values = form.getValues();
                          if (e.target.checked) {
                            form.setValue('price', values.pricePerMeasure);
                          } else {
                            form.setValue('price', round(values.pricePerMeasure * values.weight));
                          }
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Box>
              </Group>
            </Group>
          </Card.Section>
          <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
            <Grid>
              <Grid.Col span={4}>
                <Box sx={{ padding: '24px' }}>
                  <Image
                    radius="md"
                    src={defaultValues.image}
                    alt="product-image"
                    fit="contain"
                    height={350}
                  />
                </Box>
              </Grid.Col>
              <Grid.Col span={8}>
                <Grid>
                  <Grid.Col span={8}>
                    <FormController
                      name="name"
                      render={({ field, fieldState }) => (
                        <TextInput
                          {...field}
                          required
                          label={t('name')}
                          placeholder={t('input-name')}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <FormController
                      name="manufacturingCountry"
                      render={({ field, fieldState }) => (
                        <TextInput
                          {...field}
                          label={t('manufacturing-country')}
                          placeholder={t('input-manufacturing-country')}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ProductCategories />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <ProductAvailability />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <FormWatch
                      name="weighted"
                      render={weighted => (
                        <FormController
                          name="price"
                          render={({ field, fieldState }) => (
                            <NumberInput
                              {...field}
                              readOnly={weighted}
                              hideControls={weighted}
                              precision={2}
                              label={t('price')}
                              placeholder={t('input-price')}
                              error={fieldState.error?.message}
                              onChange={price => {
                                const values = form.getValues();
                                form.setValue(
                                  'pricePerMeasure',
                                  price && values.weight ? round(price / values.weight) : 0,
                                );
                                const index = values.discounts.findIndex(
                                  d => d.darkstoreId === values.darkstoreId,
                                );
                                if (index !== -1) {
                                  values.discounts[index].price =
                                    price && values.discounts[index].size > 0
                                      ? round(price - (price * values.discounts[index].size) / 100)
                                      : 0;
                                  form.setValue('discounts', values.discounts);
                                }
                                field.onChange(price);
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <FormController
                      name="weight"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          {...field}
                          precision={3}
                          label={t('weight')}
                          placeholder={t('input-weight')}
                          error={fieldState.error?.message}
                          onChange={weight => {
                            const values = form.getValues();
                            if (!values.weighted) {
                              form.setValue(
                                'pricePerMeasure',
                                weight && values.price ? round(values.price / weight) : 0,
                              );
                            }
                            field.onChange(weight);
                          }}
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <FormWatch
                      name="weighted"
                      render={weighted => (
                        <FormController
                          name="pricePerMeasure"
                          render={({ field, fieldState }) => (
                            <NumberInput
                              {...field}
                              readOnly={!weighted}
                              hideControls={!weighted}
                              precision={2}
                              label={t('price-per-measure')}
                              placeholder={t('input-price-per-measure')}
                              error={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <ProductDiscount />
                    {/* <FormController
                      name="discount"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          {...field}
                          precision={0}
                          label={t('discount')}
                          placeholder={t('input-discount')}
                          error={fieldState.error?.message}
                        />
                      )}
                    /> */}
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <ProductDiscountedPrice />
                    {/* <FormController
                      name="discountedPrice"
                      render={({ field, fieldState }) => (
                        <NumberInput
                          {...field}
                          precision={2}
                          label={t('discounted-price')}
                          placeholder={t('input-discounted-price')}
                          error={fieldState.error?.message}
                        />
                      )}
                    /> */}
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <FormController
                      name="description"
                      render={({ field, fieldState }) => (
                        <Textarea
                          {...field}
                          label={t('description')}
                          placeholder={t('input-description')}
                          error={fieldState.error?.message}
                          minRows={5}
                        />
                      )}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Card.Section>
          <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
            <Grid>
              <Grid.Col span={4}>
                <FormController
                  name="suppliers.0.supplierId"
                  render={({ field, fieldState }) => (
                    <SupplierSelect
                      {...field}
                      label={t('supplier')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <FormController
                  name="barcode"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label={t('barcode')}
                      placeholder={t('input-barcode')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <FormController
                  name="sku"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label={t('sku')}
                      placeholder={t('input-sku')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <FormController
                  name="importer"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label={t('importer')}
                      placeholder={t('input-importer')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <FormController
                  name="manufacturer"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label={t('manufacturer')}
                      placeholder={t('input-manufacturer')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <FormController
                  name="brand"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label={t('brand')}
                      placeholder={t('input-brand')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
            </Grid>
          </Card.Section>
          <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
            <Grid>
              <Grid.Col span={6}>
                <FormController
                  name="storageConditions"
                  render={({ field, fieldState }) => (
                    <Textarea
                      {...field}
                      label={t('storage-conditions')}
                      placeholder={t('input-storage-conditions')}
                      error={fieldState.error?.message}
                      minRows={5}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <FormController
                  name="content"
                  render={({ field, fieldState }) => (
                    <Textarea
                      {...field}
                      label={t('content')}
                      placeholder={t('input-content')}
                      error={fieldState.error?.message}
                      minRows={5}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span="auto">
                <FormController
                  name="minAmount"
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      precision={0}
                      label={t('min-amount')}
                      placeholder={t('input-min-amount')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span="auto">
                <FormController
                  name="suppliers.0.minRequest"
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      precision={0}
                      label={t('min-request')}
                      placeholder={t('input-min-request')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span="auto">
                <FormController
                  name="shortTerm"
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      precision={0}
                      label={t('short-term')}
                      placeholder={t('input-short-term')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span="auto">
                <FormController
                  name="suppliers.0.vat"
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      precision={0}
                      label={t('vat')}
                      placeholder={t('input-vat')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span="auto">
                <FormController
                  name="suppliers.0.supplierPrice"
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      required
                      precision={2}
                      label={t('supplier-price')}
                      placeholder={t('input-supplier-price')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span="auto">
                <FormController
                  name="suppliers.0.extraCharge"
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      required
                      precision={2}
                      label={t('extra-charge')}
                      placeholder={t('input-extra-charge')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Card radius="sm" withBorder sx={{ backgroundColor: '#F9FAFC' }}>
                  <Grid>
                    <Grid.Col span="auto">
                      <FormController
                        name="protein"
                        render={({ field, fieldState }) => (
                          <NumberInput
                            {...field}
                            precision={2}
                            label={t('protein')}
                            placeholder={t('input-protein')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span="auto">
                      <FormController
                        name="fat"
                        render={({ field, fieldState }) => (
                          <NumberInput
                            {...field}
                            precision={2}
                            label={t('fat')}
                            placeholder={t('input-fat')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span="auto">
                      <FormController
                        name="carbs"
                        render={({ field, fieldState }) => (
                          <NumberInput
                            {...field}
                            precision={2}
                            label={t('carbs')}
                            placeholder={t('input-carbs')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span="auto">
                      <FormController
                        name="kcal"
                        render={({ field, fieldState }) => (
                          <NumberInput
                            {...field}
                            precision={2}
                            label={t('kcal')}
                            placeholder={t('input-kcal')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span="auto">
                      <FormController
                        name="kj"
                        render={({ field, fieldState }) => (
                          <NumberInput
                            {...field}
                            precision={2}
                            label={t('kj')}
                            placeholder={t('input-kj')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid.Col>
                  </Grid>
                </Card>
              </Grid.Col>
            </Grid>
          </Card.Section>
          <Card.Section sx={{ padding: 16 }}>
            <Button
              type="submit"
              disabled={disabled}
              loading={isSubmitting}
              sx={{ display: 'block', marginLeft: 'auto' }}
            >
              {t('save')}
            </Button>
          </Card.Section>
        </Card>
      )}
    </Form>
  );
};
