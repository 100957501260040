import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { theme } from './theme';

export const MantineProviders = ({ children }: { children: ReactNode }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{ ...theme, datesLocale: language }}>
      <NotificationsProvider position="bottom-left">
        <ModalsProvider modalProps={{ centered: true, closeOnClickOutside: false }}>
          {children}
        </ModalsProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
};
