import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { SupplierProduct } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';

export const SupplierProductsDataGrid = ({ data }: { data: SupplierProduct[] }) => {
  const { t } = useTranslation('features/suppliers', { keyPrefix: 'supplier-products-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SupplierProduct>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`/products/${value}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('name', {
        size: 250,
        header: () => t('columns.1'),
      }),
      columnHelper.accessor('barcode', { size: 150, header: () => t('columns.2') }),
    ];
  }, [t]);

  return <DataGrid columns={columns} data={data} sorting sx={{ minHeight: '300px' }} />;
};
