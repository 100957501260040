import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { SupplierSupply } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { formatDate } from 'shared/utils/date';

export const SupplierSuppliesDataGrid = ({ data }: { data: SupplierSupply[] }) => {
  const { t } = useTranslation('features/suppliers', { keyPrefix: 'supplier-supplies-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SupplierSupply>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`/supplies/${value}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('number', {
        size: 150,
        header: () => t('columns.1'),
      }),
      columnHelper.accessor('sumWithoutVat', {
        size: 150,
        header: () => t('columns.2'),
      }),
      columnHelper.accessor('sumWithVat', {
        size: 150,
        header: () => t('columns.3'),
      }),
      columnHelper.accessor('createdAt', {
        size: 150,
        header: () => t('columns.4'),
        cell: params => formatDate(params.getValue()),
      }),
      columnHelper.accessor('completedAt', {
        size: 150,
        header: () => t('columns.5'),
        cell: params => formatDate(params.getValue()),
      }),
      columnHelper.accessor('payedAt', {
        size: 150,
        header: () => t('columns.6'),
        cell: params => {
          const value = params.getValue();
          if (!value) {
            return '';
          }
          return formatDate(value);
        },
      }),
    ];
  }, [t]);

  return <DataGrid columns={columns} data={data} sorting sx={{ minHeight: '300px' }} />;
};
