import { z } from 'zod';
import { SupplyStatus } from 'shared/lib/bazar-api';

export const supplyFormSchema = z.object({
  status: z.nativeEnum(SupplyStatus),
  expectedAt: z.date(),
});

export const supplyProductFormSchema = z.object({
  productId: z.number().int().min(0),
  productName: z.string().optional(),
  amount: z.number().int().positive(),
});
