import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Radio, Stack, Tabs } from '@mantine/core';
import dayjs from 'dayjs';
import { useDarkstore } from 'features/darkstores';
import { useSettings } from 'features/settings';
import { Darkstore } from 'shared/lib/bazar-api';

const getInitialTime = (value: { deliveryDate: Date; deliveryTime: string }) => {
  if (value.deliveryTime === 'now') {
    return value.deliveryTime;
  }
  const tmp = dayjs(value.deliveryTime, 'HH:mm');
  return dayjs(value.deliveryDate)
    .set('h', tmp.get('h'))
    .set('m', tmp.get('m'))
    .set('s', 0)
    .toISOString();
};

const makeItems = (from: dayjs.Dayjs, to: dayjs.Dayjs) => {
  const items = [];
  let current = dayjs(from);
  while (current.isBefore(to) || current.isSame(to)) {
    items.push({ label: current.format('HH:mm'), value: current.toISOString() });
    current = current.add(30, 'm');
  }
  return items;
};

const useItems = ({ baseDate, schedule }: { baseDate: Date; schedule?: Darkstore['schedule'] }) => {
  return useMemo(() => {
    const items: {
      today: { label: string; value: string }[];
      tomorrow: { label: string; value: string }[];
    } = { today: [], tomorrow: [] };

    if (!schedule) {
      return items;
    }

    const today = dayjs(baseDate).set('s', 0).set('ms', 0);
    const todaySchedule = schedule[today.day() + 1];
    const todayOpen = today
      .set('h', todaySchedule.start.getHours())
      .set('m', todaySchedule.start.getMinutes());
    const todayClose = today
      .set('h', todaySchedule.finish.getHours())
      .set('m', todaySchedule.finish.getMinutes());

    // TODO: use actual deliveryTime from darkstore zone
    const delivery = today.add(15, 'm');
    if (todayOpen < delivery && delivery < todayClose) {
      items.today.push({ label: 'now', value: 'now' });
      const shiftedDelivery = delivery.subtract(delivery.get('m') % 30, 'm').add(30, 'm');
      items.today.push(...makeItems(shiftedDelivery, todayClose));
    }

    const tomorrow = today.add(1, 'day');
    const tomorrowSchedule = schedule[tomorrow.day() + 1];
    const tomorrowOpen = tomorrow
      .set('h', tomorrowSchedule.start.getHours())
      .set('m', tomorrowSchedule.start.getMinutes());
    const tomorrowClose = tomorrow
      .set('h', tomorrowSchedule.finish.getHours())
      .set('m', tomorrowSchedule.finish.getMinutes());

    items.tomorrow.push({ label: tomorrowOpen.format('HH:mm'), value: tomorrowOpen.toISOString() });
    const shiftedOpen = tomorrowOpen.subtract(tomorrowOpen.get('m') % 30, 'm').add(30, 'm');
    items.tomorrow.push(...makeItems(shiftedOpen, tomorrowClose));

    return items;
  }, [baseDate, schedule]);
};

export const DeliveryToModal = ({
  baseDate,
  value,
  onChange,
}: {
  baseDate: Date;
  value: { deliveryDate: Date; deliveryTime: string };
  onChange: (value: { deliveryDate: Date; deliveryTime: string }) => void;
}) => {
  const { t } = useTranslation('features/orders', { keyPrefix: 'delivery-to-modal' });

  const darkstoreId = useSettings(settings => settings.darkstoreId);
  const { darkstore } = useDarkstore({ darkstoreId });

  const items = useItems({ baseDate, schedule: darkstore?.schedule });
  const [time, setTime] = useState(getInitialTime(value));

  const handleClick = () => {
    if (time === 'now') {
      onChange({
        deliveryDate: dayjs(baseDate).startOf('day').toDate(),
        deliveryTime: 'now',
      });
    } else {
      onChange({
        deliveryDate: dayjs(time).startOf('day').toDate(),
        deliveryTime: dayjs(time).format('HH:mm:ss'),
      });
    }
  };

  return (
    <Stack>
      <Tabs defaultValue="today">
        <Tabs.List>
          <Tabs.Tab value="today">{t('today')}</Tabs.Tab>
          <Tabs.Tab value="tomorrow">{t('tomorrow')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="today" pt="xs">
          <Radio.Group orientation="vertical" value={time} onChange={setTime}>
            {items.today.map(item => (
              <Radio
                key={item.value}
                label={item.label === 'now' ? t('now') : item.label}
                value={item.value}
              />
            ))}
          </Radio.Group>
        </Tabs.Panel>
        <Tabs.Panel value="tomorrow" pt="xs">
          <Radio.Group orientation="vertical" value={time} onChange={setTime}>
            {items.tomorrow.map(item => (
              <Radio key={item.value} label={item.label} value={item.value} />
            ))}
          </Radio.Group>
        </Tabs.Panel>
      </Tabs>
      <Button sx={{ display: 'block', marginLeft: 'auto' }} onClick={handleClick}>
        {t('save')}
      </Button>
    </Stack>
  );
};
