import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ActionIcon, Anchor, Menu } from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  DotsVertical as DotsVerticalIcon,
  Pencil as PencilIcon,
  Trash as TrashIcon,
} from 'tabler-icons-react';
import { Suspense } from 'shared/components';
import { Order, OrderProduct } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { OrderProductEditModal } from './order-product-edit-modal';
import { OrderProductRemoveModal } from './order-product-remove-modal';

export const OrderProductsDataGrid = ({
  orderId,
  data,
  actionsColumn,
}: {
  orderId: Order['id'];
  data: OrderProduct[];
  actionsColumn?: boolean;
}) => {
  const { t } = useTranslation('features/orders', {
    keyPrefix: 'order-products-data-grid',
  });
  const modals = useModals();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<OrderProduct>();
    const dataColumns = [
      columnHelper.accessor('id', {
        size: 100,
        // meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`/products/${value}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('name', { size: 350, header: () => t('columns.1') }),
      columnHelper.accessor('amount', { size: 150, header: () => t('columns.2') }),
      columnHelper.accessor('price', { size: 150, header: () => t('columns.3') }),
      columnHelper.accessor('priceCatalog', { size: 150, header: () => t('columns.4') }),
      columnHelper.accessor('barcode', { size: 150, header: () => t('columns.5') }),
    ];
    if (actionsColumn) {
      return [
        columnHelper.display({
          id: 'actions',
          size: 50,
          meta: { sticky: true },
          cell: params => {
            return (
              <Menu position="bottom-start">
                <Menu.Target>
                  <ActionIcon>
                    <DotsVerticalIcon size={14} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component="button"
                    icon={<PencilIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('edit-product'),
                        children: (
                          <Suspense>
                            <OrderProductEditModal
                              orderId={orderId}
                              product={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('edit')}
                  </Menu.Item>
                  <Menu.Item
                    component="button"
                    icon={<TrashIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('remove-product'),
                        children: (
                          <Suspense>
                            <OrderProductRemoveModal
                              orderId={orderId}
                              productId={params.row.original.id}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('remove')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );
          },
        }),
        ...dataColumns,
      ];
    }
    return dataColumns;
  }, [orderId, actionsColumn, t, modals]);

  return <DataGrid columns={columns} data={data} sx={{ minHeight: '500px' }} />;
};
