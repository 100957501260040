import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useProduct = ({ productId }: { productId?: number }) => {
  const partnerId = useSettings(settings => settings.partnerId);
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['products', { productId, darkstoreId }],
    queryFn: () => {
      if (productId) {
        return bazarApi.assortment.getOneProduct({ id: productId, partnerId, darkstoreId });
      }
    },
    enabled: !!productId,
    suspense: true,
  });

  return { isFetching, product: data };
};
