import { useTranslation } from 'react-i18next';
import { Group, Stack, Title } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { CashDataGrid } from 'features/cash-accounting';
import { useSearch } from 'shared/lib/search';
import { formatDate, parseDate } from 'shared/utils/date';

export const CashAccountingPage = () => {
  const { t } = useTranslation('pages/cash-accounting');

  const [search, setSearch] = useSearch({
    date: formatDate(new Date(), 'YYYY-MM-DD'),
  });

  const filters = { date: parseDate(search.date) };

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('cash-accounting')}</Title>
      <Group>
        <DatePicker
          clearable={false}
          label={t('date')}
          placeholder={t('select-date')}
          value={filters.date}
          onChange={date => {
            if (date) {
              setSearch({ date: formatDate(date, 'YYYY-MM-DD') });
            }
          }}
        />
      </Group>
      <CashDataGrid {...filters} />
    </Stack>
  );
};
