import { Box, Title } from '@mantine/core';
import type { MantineTheme } from '@mantine/styles';

export const NotFoundPage = () => {
  return (
    <Box
      sx={(theme: MantineTheme) => ({
        padding: theme.spacing.md,
      })}
    >
      <Title align="center">Page not found</Title>
    </Box>
  );
};
