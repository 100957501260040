export type Category = {
  id: number;
  name: string;
  image: string;
  icon: string;
  order: number;
  promoted: boolean;
};

export type Subcategory = {
  id: number;
  categoryId: Category['id'];
  name: string;
  order: number;
};

export type ProductRow = {
  id: number;
  enabled: boolean;
  name: string;
  price: number;
  discount: number;
  discountedPrice: number;
  extraCharge: number;
  amount: number;
  barcode: string;
  supplierName: string;
  subcategoriesNames: string;
};

export enum ProductDiscountType {
  Default = 'standart',
  ByDate = 'limited',
  Daily = 'daily',
}

export type Product = {
  id: number;
  partnerId: number;
  darkstoreId: Darkstore['id'];
  categories: {
    id: Category['id'];
    subcategories: { id: Subcategory['id'] }[];
  }[];
  availability: {
    darkstoreId: Darkstore['id'];
    amount: number;
  }[];
  discounts: {
    darkstoreId: Darkstore['id'];
    type: ProductDiscountType;
    from: Date | null;
    to: Date | null;
    date: Date | null;
    size: number;
    price: number;
  }[];
  suppliers: {
    supplierId: Supplier['id'];
    supplierPrice: number;
    minRequest: number;
    vat: number;
    extraCharge: number;
  }[];
  enabled: boolean;
  liquidating: boolean;
  splitting: boolean;
  weighted: boolean;
  image: string;
  name: string;
  country: string;
  price: number;
  liquid: boolean;
  weight: number;
  pricePerMeasure: number;
  description: string;
  barcode: string;
  sku: string;
  importer: string;
  manufacturer: string;
  brand: string;
  storageConditions: string;
  content: string;
  minAmount: number;
  shortTerm: number;
  protein: number;
  fat: number;
  carbs: number;
  kcal: number;
  kj: number;
};

export type Darkstore = {
  id: number;
  zones: DarkstoreZone[];
  schedule: Record<number, { start: Date; finish: Date }>;
  name: string;
  address: string;
  phone: string;
  phones: { icon: string; phone: string }[];
  links: { title: string; url: string }[];
  legal: string;
};

export type DarkstoreZone = {
  id: number;
  darkstoreId: Darkstore['id'];
  status: DarkstoreZoneStatus;
  paymentMethods: PaymentMethod[];
  points: DarkstoreZonePoint[];
  name: string;
  defaultDeliveryTime: number;
  deliveryTime: number;
  deliveryCost: number;
  minOrderSum: number;
};

export enum DarkstoreZoneStatus {
  Working = 0,
  TechnicalBreak = 1,
  IncreasedDemand = 2,
  BadWeather = 3,
  TemporalityDown = 4,
}

export type DarkstoreZonePoint = { lat: number; lng: number };

export type User = {
  id: number;
  partnerId: number; // can be null ?_?
  providerId: number | null;
  email: string;
  name: string;
  surname: string;
  password: string | null;
  role: UserRole;
  darkstores: Darkstore['id'][];
  acl: Record<string, { read: boolean; write: boolean }>;
};

export enum UserRole {
  Admin = 'admin',
  Manager = 'manager',
  Supervisor = 'supervisor',
  Marketing = 'marketing',
  HR = 'hr',
  Support = 'support',
  ProcurementSpecialist = 'procurement_specialist',
}

export type OrderRow = {
  id: number;
  courierId: Employee['id'] | null;
  status: OrderStatus;
  paymentMethod: PaymentMethod;
  customerName: string | null;
  customerPhone: string;
  courierName: string | null;
  courierSurname: string | null;
  paid: boolean;
  sum: number;
  deliveryDate: Date;
  deliveryTime: string;
  createdAt: Date;
  deliveredAt: Date | null;
  deliveredIn: number | null;
  plannedTimeDelivery: string | null;
  idSosedi: string | null;
  idYandex: string | null;
  idDelivio: string | null;
};

export type Order = {
  id: number;
  courierId: Employee['id'] | null;
  pickerId: Employee['id'] | null;
  status: OrderStatus;
  paymentMethod: PaymentMethod;
  products: OrderProduct[];
  customerName: string | null;
  customerPhone: string;
  customerComment: string;
  address: string;
  promocode: string | null;
  bonuses: number | null;
  paid: boolean;
  sum: number;
  deliveryDate: Date;
  deliveryTime: string;
  courierComment: string | null;
  pickerComment: string | null;
  createdAt: Date;
  pickedAt: Date | null;
  deliveredAt: Date | null;
  idSosedi: string | null;
  idYandex: string | null;
  idDelivio: string | null;
};

export type OrderProduct = {
  id: number;
  name: string;
  barcode: string;
  amount: number;
  price: number;
  priceCatalog: number;
};

export enum OrderStatus {
  New = 'NEW',
  Collecting = 'IN_PROCESS',
  Collected = 'PROCESSED',
  Delivering = 'IN_DELIVERY',
  Transferred = 'TRANSFERRED',
  Delivered = 'DELIVERED',
  Canceled = 'CANCELED',
}

export enum PaymentMethod {
  Cash = 1,
  CardOffline = 2,
  CardOnline = 3,
  ApplePay = 4,
  GooglePay = 5,
  TransferOnline = 6,
  Oplati = 7,
}

export type Employee = {
  id: number;
  role: EmployeeRole;
  status: EmployeeStatus;
  phone: string;
  name: string;
  surname: string;
  password: string | null;
  photo: string;
};

export enum EmployeeRole {
  Courier = 'courier',
  Picker = 'picker',
}

export enum EmployeeStatus {
  Outside = 0,
  Working = 1,
  Sick = 2,
  Vacation = 3,
  Dismissed = 4,
}

export type SupplierRow = {
  id: number;
  name: string;
  upn: string;
  contact: string;
  phone: string;
  days: string;
};

export type Supplier = {
  id: number;
  darkstoreId: Darkstore['id'];
  type: SupplierType;
  paymentTermType: PaymentTermType;
  products: SupplierProduct[];
  supplies: SupplierSupply[];
  name: string;
  upn: string;
  contractNumber: string;
  contractUrl: string | null;
  contacts: {
    name: string;
    phone: string;
    birthday: Date | null;
    email: string;
    comment: string;
  }[];
  requestDays: number[];
  paymentDelay: number;
  paymentTermComment: string;
  comment: string;
  email: string;
  requisites: string;
};

export type SupplierProduct = {
  id: number;
  name: string;
  barcode: string;
  amount: number;
  sold: number;
  writeOff: number;
  type: boolean;
  enabled: boolean;
  weighted: boolean;
  minRequest: number;
  weight: number;
  liquid: boolean;
  priceWithoutVat: number;
  // virtual field
  request: number;
};

export type SupplierSupply = {
  id: number;
  number: string;
  sumWithVat: number;
  sumWithoutVat: number;
  createdAt: Date;
  completedAt: Date;
  payedAt: Date | null;
};

export enum SupplierType {
  Importer = 'importer',
  Distributor = 'distributor',
  Manufacturer = 'manufacturer',
}

export enum PaymentTermType {
  Prepayment = 'prepayment',
  Postponement = 'postponement',
}

export type SupplyRow = {
  id: number;
  darkstoreId: Darkstore['id'];
  supplierId: Supplier['id'];
  status: SupplyStatus;
  number: string;
  darkstoreName: string;
  supplierName: string;
  sumWithVat: number;
  amount: number;
  createdAt: Date;
  expectedAt: Date;
};

export type Supply = {
  id: number;
  darkstoreId: Darkstore['id'];
  supplierId: Supplier['id'];
  status: SupplyStatus;
  products: SupplyProduct[];
  number: string;
  darkstoreName: string;
  supplierName: string;
  consignmentNoteNumber: number;
  sumWithoutVat: number;
  vat: number;
  sumWithVat: number;
  billUrl: string | null;
  paymentOrderUrl: string | null;
  waybillUrl: string | null;
  createdAt: Date;
  expectedAt: Date;
};

export type SupplyProduct = {
  id: number;
  name: string;
  barcode: string;
  weight: number;
  liquid: boolean;
  amount: number;
  priceWithoutVat: number;
  sumWithoutVat: number;
  vatPercent: number;
  vat: number;
  vatSum: number;
  extraCharge: number;
  price: number;
  sku: string;
};

export enum SupplyStatus {
  Pending = 1,
  Completed = 2,
  Canceled = 3,
}

export type PromoCode = {
  id: number;
  darkstoreId: Darkstore['id'];
  type: PromoCodeType;
  statistic: PromoCodeStatistic;
  name: string;
  value: number;
  debitingLimit: number;
  blacklist: { street: string; house: string; flat: string }[];
  forOneOrder: boolean;
  forFirstOrder: boolean;
  indefinite: boolean;
  startedAt: Date;
  endedAt: Date;
};

export enum PromoCodeType {
  Numeric = 'sum',
  Percent = 'proc',
}

export type PromoCodeStatistic = {
  used: number;
  one: number;
  two: number;
  three: number;
  sum: number;
};

export type PromoCodesCampaign = {
  id: number;
  statistic: PromoCodeStatistic;
  promocodes: PromoCode[];
  name: string;
};

export type Address = {
  name: string;
  name_short: string;
  geoid: number;
  kind: string;
  lat: number;
  lon: number;
  url: string;
  hl: (number | string)[][];
};

export enum BannerActionType {
  Category = 'category',
  Subcategory = 'subCategory',
  //Brand = 'brand',
  //Screen = 'screen',
  //Modal = 'modal',
  Stories = 'stories',
}

export enum BannerSize {
  Small = 'small',
  Big = 'big',
}

export enum BannerTargetScreen {
  Special = 'Special',
  Favorites = 'Favourites',
  Profile = 'Profile',
  Virality = 'MyVirality',
}

export enum BannerButtonType {
  Category = 'category',
  Subcategory = 'subCategory',
  Brand = 'brand',
  Screen = 'screen',
}

export type Banner = {
  id: number;
  darkstoreId: Darkstore['id'];
  size: BannerSize;
  actionType: BannerActionType;
  appImage: string;
  webImage: string;
  targetCategory: number | null;
  targetSubcategory: number | null;
  targetBrand: number | null;
  targetScreen: BannerTargetScreen | null;
  modal: {
    title: string;
    text: string;
    image: string;
    buttonType: BannerButtonType;
    buttonText: string;
    targetCategory: number | null;
    targetSubcategory: number | null;
    targetBrand: number | null;
    targetScreen: BannerTargetScreen | null;
  } | null;
  stories: {
    title: string;
    text: string;
    image: string;
    buttonType: BannerButtonType;
    buttonText: string;
    targetCategory: number | null;
    targetSubcategory: number | null;
    targetBrand: number | null;
    targetScreen: BannerTargetScreen | null;
  }[];
};

export enum FileType {
  Document = 'doc',
  Banner = 'banner',
  Product = 'product',
  Category = 'category',
}

export type CashRow = {
  id: number;
  employeeId: Employee['id'];
  employeeName: Employee['name'];
  employeeSurname: Employee['surname'];
  cashRegister: number;
  deposit: number;
  cash: number;
  card: number;
  deficit: number;
};

export type WriteOffRow = {
  productId: Product['id'];
  employeeId: Employee['id'];
  productName: Product['name'];
  productAmount: number;
  productPrice: Product['price'];
  productSupplierPrice: number;
  productVat: number;
  reason: string;
  employeeName: Employee['name'];
  employeeSurname: Employee['surname'];
  createdAt: Date;
};

export enum WriteOffReason {
  WriteOff = 'write-off',
  Return = 'return',
  Moving = 'moving',
  Lack = 'lack',
  ReGrading = 're-grading',
}

export enum WriteOffType {
  Any = 'any',
  Plus = 'plus',
  Minus = 'minus',
}

export type CustomerRow = {
  id: number;
  name: string;
  surname: string;
  phone: string;
  ordersAmount: number;
  ordersSum: number;
  averageOrderSum: number | null;
  averageOrderSize: number | null;
  lastOrderedAt: Date | null;
};

export type Customer = {
  id: number;
  name: string;
  surname: string;
  phone: string;
  addresses: CustomerAddress[];
  orders: CustomerOrder[];
  ordersAmount: number;
  ordersSum: number;
  averageOrderSum: number | null;
  averageOrderSize: number | null;
  invitedCustomersAmount: number;
  bonusesReceivedSum: number;
  bonusesSpentSum: number;
  lastMonthOrdersAmount: number;
  lastWeekOrdersAmount: number;
  createdAt: Date;
  bornAt: Date | null;
  firstOrderedAt: Date | null;
  lastOrderedAt: Date | null;
};

export type CustomerAddress = {
  street: string;
  building: string;
  house: string;
  flat: string;
  entrance: string;
  floor: string;
  intercom: string;
};

export type CustomerOrder = {
  id: number;
  courierId: Employee['id'] | null;
  courierName: Employee['name'] | null;
  courierSurname: Employee['surname'] | null;
  status: OrderStatus;
  paymentMethod: PaymentMethod;
  sum: number;
  createdAt: Date;
};

export type BlacklistAddress = {
  id: number;
  darkstoreId: Darkstore['id'];
  type: BlacklistAddressType;
  street: string;
  building: string;
  house: string;
  flat: string;
};

export enum BlacklistAddressType {
  Using = 'using',
  Virality = 'virality',
}

export type BlacklistCustomer = {
  id: number;
  partnerId: number;
  phone: string;
  minOrderSum: number;
  amnesty: number;
};

export type ViralityStatistic = {
  invites: number;
  one: number;
  two: number;
  three: number;
  sum: number;
};

export type ViralityRow = {
  customer: Pick<Customer, 'id' | 'name' | 'surname' | 'phone'>;
  statistic: ViralityStatistic;
};

export enum MailingDevice {
  Android = 'android',
  IOS = 'ios',
  Web = 'web',
}

export enum MailingOrder {
  One = 'one',
  NoOne = 'no_one',
  MoreThanOne = 'more_1',
  MoreThanTwo = 'more_2',
  MoreThanThree = 'more_3',
  MoreThanFour = 'more_4',
  MoreThanSix = 'more_6',
  MoreThanTwelve = 'more_12',
}

export enum MailingAvailability {
  OneOf = 'one-of',
  All = 'all',
}

export type Mailing = {
  id: number;
  partnerId: number;
  title: string;
  text: string;
  url: string;
  settings: {
    darkstores: number[];
    devices: MailingDevice[];
    availability: MailingAvailability | null;
    products: number[];
    orders: MailingOrder | null;
  };
  createdAt: Date;
};

export enum StopListReason {
  wrongQty = 'wrongQty',
  onWay = 'onWay',
  discount = 'discount',
  bakery = 'bakery',
  defect = 'defect',
  defectFROV = 'defectFROV',
}

export type StopListProduct = {
  cod: string;
  name: string;
};

export type StopList = {
  id: number;
  darkstoreId: number;
  finish: string;
  product: StopListProduct;
  productId: number;
  qtyStart: number;
  qtyFinish: number;
  qtyRenewal: number;
  reason: StopListReason;
  start: string;
  term: string;
};
