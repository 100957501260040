import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Select, SelectProps } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Employee, EmployeeRole } from 'shared/lib/bazar-api';
import { useEmployees } from '../hooks';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  role: EmployeeRole;
  value?: Employee['id'] | null;
  onChange: (value: Employee['id']) => void;
};

export const EmployeeSelect = forwardRef<HTMLInputElement, Props>(
  ({ role, value, onChange, ...rest }, ref) => {
    const { t } = useTranslation('features/employees', { keyPrefix: 'employee-select' });

    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 300);
    const { isFetching, employees } = useEmployees({ role, query: debouncedQuery, pageSize: 1000 });

    const data: SelectItem[] = employees.map(employee => ({
      value: employee.id.toString(),
      label: `${employee.surname} ${employee.name}`,
    }));

    return (
      <Select
        ref={ref}
        searchable
        placeholder={t('select-employee')}
        data={data}
        value={value?.toString()}
        nothingFound={t('nothing-found')}
        filter={() => true}
        rightSection={isFetching && <Loader size="xs" />}
        onChange={newValue => onChange(Number(newValue))}
        onSearchChange={setQuery}
        {...rest}
      />
    );
  },
);
