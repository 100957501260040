import { z } from 'zod';
import { validatePhone } from 'shared/utils/phone';

export const customerFormSchema = z.object({
  name: z.string().min(1),
  surname: z.string(),
  bornAt: z.date().nullable(),
  phone: z.string().refine(validatePhone),
  addresses: z
    .array(
      z.object({
        street: z.string().min(1),
        house: z.string().min(1),
        building: z.string(),
        flat: z.string().min(1),
        entrance: z.string(),
        floor: z.string(),
        intercom: z.string(),
      }),
    )
    .min(1),
});
