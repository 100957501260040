import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { ConfirmModal } from 'shared/components';
import { BlacklistAddress } from 'shared/lib/bazar-api';
import { useRemoveBlacklistAddress } from '../hooks';

export const BlacklistAddressRemoveModal = ({
  address,
  onClose,
}: {
  address: BlacklistAddress;
  onClose: () => void;
}) => {
  const { t } = useTranslation('features/blacklist', {
    keyPrefix: 'blacklist-address-remove-modal',
  });

  const { isRemoving, remove } = useRemoveBlacklistAddress({ onSuccess: onClose });

  return (
    <ConfirmModal
      labels={{
        confirm: t('remove-blacklist-address'),
        cancel: t('dont-remove-blacklist-address'),
      }}
      confirmProps={{ loading: isRemoving, color: 'red' }}
      onCancel={onClose}
      onConfirm={() => remove({ addressId: address.id })}
    >
      <Text size="sm">{t('blacklist-address-remove-confirmation')}</Text>
    </ConfirmModal>
  );
};
