import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type RenderItem = (
  props: { id: string; style: { transform?: string; transition?: string } } & Pick<
    ReturnType<typeof useSortable>,
    'attributes' | 'listeners' | 'setNodeRef'
  >,
) => JSX.Element | null;

const SortableItem = ({ id, renderItem }: { id: string; renderItem: RenderItem }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return renderItem({
    id,
    style,
    attributes,
    listeners,
    setNodeRef,
  });
};

export const SortableList = ({
  items,
  renderItem,
  onItemsChange,
}: {
  items: string[];
  renderItem: RenderItem;
  onItemsChange: (newItems: string[]) => void;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const newItems = [...items];
      const oldIndex = newItems.indexOf(active.id.toString());
      const newIndex = newItems.indexOf(over.id.toString());
      onItemsChange(arrayMove(newItems, oldIndex, newIndex));
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map(id => (
          <SortableItem key={id} id={id} renderItem={renderItem} />
        ))}
      </SortableContext>
    </DndContext>
  );
};
