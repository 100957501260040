import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import {
  Darkstore,
  Supplier,
  WriteOffReason,
  WriteOffRow,
  WriteOffType,
} from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { formatDateTime } from 'shared/utils/date';
import { useWriteOffs } from '../hooks';

export const WriteOffsDataGrid = ({
  darkstoreId,
  reason,
  month,
  year,
  supplierId,
  from,
  to,
  type,
}: {
  darkstoreId?: Darkstore['id'];
  reason: WriteOffReason;
  month: number;
  year: number;
  supplierId?: Supplier['id'];
  from: Date;
  to: Date;
  type: WriteOffType;
}) => {
  const { t } = useTranslation('features/write-offs', { keyPrefix: 'write-offs-data-grid' });

  const { isFetching, writeOffs } = useWriteOffs({
    darkstoreId,
    reason,
    month,
    year,
    supplierId,
    from,
    to,
    type,
  });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<WriteOffRow>();
    return [
      columnHelper.accessor('productId', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`/products/${value}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('productName', {
        size: 200,
        header: () => t('columns.1'),
      }),
      columnHelper.accessor('createdAt', {
        size: 200,
        header: () => t('columns.2'),
        cell: params => formatDateTime(params.getValue()),
      }),
      columnHelper.accessor('productAmount', {
        size: 100,
        header: () => t('columns.3'),
      }),
      columnHelper.accessor('reason', {
        size: 150,
        header: () => t('columns.4'),
      }),
      columnHelper.accessor(
        row => {
          return `${row.employeeName ?? ''} ${row.employeeSurname ?? ''}`.trim();
        },
        {
          id: 'employeeInfo',
          size: 250,
          header: () => t('columns.5'),
        },
      ),
      columnHelper.accessor('productSupplierPrice', {
        size: 125,
        header: () => t('columns.6'),
      }),
      columnHelper.accessor('productPrice', {
        size: 150,
        header: () => t('columns.7'),
      }),
      columnHelper.accessor('productVat', {
        size: 75,
        header: () => t('columns.8'),
      }),
    ];
  }, [t]);

  return <DataGrid loading={isFetching} columns={columns} data={writeOffs} sorting />;
};
