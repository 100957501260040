import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { ConfirmModal } from 'shared/components';
import { Supply, SupplyProduct } from 'shared/lib/bazar-api';
import { useRemoveSupplyProduct } from '../hooks';

export const SupplyProductRemoveModal = ({
  supplyId,
  productId,
  onClose,
}: {
  supplyId: Supply['id'];
  productId: SupplyProduct['id'];
  onClose: () => void;
}) => {
  const { t } = useTranslation('features/supplies', { keyPrefix: 'supply-product-remove-modal' });

  const { isRemoving, remove } = useRemoveSupplyProduct({ onSuccess: onClose });

  return (
    <ConfirmModal
      labels={{ confirm: t('remove-product'), cancel: t('dont-remove-product') }}
      confirmProps={{ loading: isRemoving, color: 'red' }}
      onCancel={onClose}
      onConfirm={() => remove({ supplyId, productId })}
    >
      <Text size="sm">{t('product-remove-confirmation')}</Text>
    </ConfirmModal>
  );
};
