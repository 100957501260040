import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BlacklistAddress } from 'shared/lib/bazar-api';
import { bazarApi } from 'shared/lib/bazar-api';

type RemoveBlacklistAddressDTO = {
  addressId: BlacklistAddress['id'];
};

export const useRemoveBlacklistAddress = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: RemoveBlacklistAddressDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, RemoveBlacklistAddressDTO>({
    mutationFn: async variables => {
      return bazarApi.blacklist.removeAddress({ id: variables.addressId });
    },
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Адрес удален из черного списка',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['blacklist-addresses'] });
      onSuccess(data, variables);
    },
  });

  return { isRemoving: isLoading, remove: mutate };
};
