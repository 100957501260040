import { createContext } from 'react';
import { User } from 'shared/lib/bazar-api';

export const AuthContext = createContext<
  | {
      user?: User;
      signIn: (user: any) => void;
      signOut: () => void;
    }
  | undefined
>(undefined);
