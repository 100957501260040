import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { CashRow } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { formatDate } from 'shared/utils/date';
import { useCash } from '../hooks';

export const CashDataGrid = ({ date }: { date: Date }) => {
  const { t } = useTranslation('features/cash-accounting', { keyPrefix: 'cash-data-grid' });

  const { isFetching, cash } = useCash({ date });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<CashRow>();
    return [
      columnHelper.accessor(
        row => {
          return `${row.employeeName ?? ''} ${row.employeeSurname ?? ''}`.trim();
        },
        {
          id: 'employee-info',
          size: 200,
          meta: { sticky: true },
          header: () => t('columns.0'),
          cell: params => {
            const searchParams = new URLSearchParams({
              from: formatDate(date, 'YYYY-MM-DD'),
              to: formatDate(date, 'YYYY-MM-DD'),
              courierId: params.row.original.employeeId.toString(),
            });
            return (
              <Anchor component={Link} to={`/orders?${searchParams.toString()}`}>
                {params.getValue()}
              </Anchor>
            );
          },
        },
      ),
      columnHelper.accessor('cashRegister', { header: () => t('columns.1') }),
      columnHelper.accessor('deposit', { header: () => t('columns.2') }),
      columnHelper.accessor('cash', { header: () => t('columns.3') }),
      columnHelper.accessor('card', { header: () => t('columns.4') }),
      columnHelper.accessor('deficit', { header: () => t('columns.5') }),
    ];
  }, [date, t]);

  return <DataGrid loading={isFetching} columns={columns} data={cash} sorting />;
};
