import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Customer } from 'shared/lib/bazar-api';
import type { CustomerFormValues } from '../types';

type EditCustomerDTO = {
  customerId: Customer['id'];
  data: CustomerFormValues;
};

export const useEditCustomer = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, EditCustomerDTO>({
    mutationFn: variables => {
      return bazarApi.customers.edit({
        id: variables.customerId,
        ...variables.data,
      });
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
  });

  return { isEditing: isLoading, edit: mutate };
};
