import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  fontFamily: 'Roboto, sans-serif',
  primaryColor: 'orange',
  headings: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    Button: {
      styles: {
        label: {
          fontWeight: 500,
        },
      },
    },
    MultiSelect: {
      styles: theme => ({
        searchInput: {
          fontFamily: theme.fontFamily,
        },
      }),
    },
    Menu: {
      defaultProps: {
        withinPortal: true,
      },
    },
    DatePicker: {
      defaultProps: {
        withinPortal: true,
      },
    },
  },
};
