import { useTranslation } from 'react-i18next';
import { Button, Stack, Switch, TextInput } from '@mantine/core';
import { FileDropzone } from 'shared/components';
import { FileType } from 'shared/lib/bazar-api';
import { Form, FormController } from 'shared/lib/form';
import { categoryFormSchema } from '../schemas';
import type { CategoryFormValues } from '../types';

export const CategoryForm = ({
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  isSubmitting: boolean;
  defaultValues: Partial<CategoryFormValues>;
  onSubmit: (values: CategoryFormValues) => void;
}) => {
  const { t } = useTranslation('features/categories', { keyPrefix: 'category-form' });

  return (
    <Form defaultValues={defaultValues} validationSchema={categoryFormSchema} onSubmit={onSubmit}>
      <Stack align="stretch">
        <FormController
          name="name"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              required
              label={t('name')}
              placeholder={t('input-name')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="image"
          render={({ field, fieldState }) => {
            return (
              <FileDropzone
                type={FileType.Category}
                required
                label={t('image')}
                error={fieldState.error?.message}
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        />
        <FormController
          name="icon"
          render={({ field, fieldState }) => {
            return (
              <FileDropzone
                type={FileType.Category}
                required
                label={t('icon')}
                error={fieldState.error?.message}
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        />
        <FormController
          name="promoted"
          render={({ field }) => (
            <Switch label={t('promo-category')} checked={field.value} onChange={field.onChange} />
          )}
        />
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t('save')}
        </Button>
      </Stack>
    </Form>
  );
};
