// import { get } from 'shared/utils/object';
import { useAuth } from './use-auth';

export const usePermission = (permissionName: string) => {
  const auth = useAuth();

  if (!auth.user) {
    return false;
  }

  return true;
  // return !!get(auth.user.acl, permissionName);
};
