import { FILESTORAGE_URL } from 'shared/config/env';
import { bazarApiClient } from '../client';
import { Banner, BannerSize, Darkstore } from '../types';

const getList = async ({ darkstoreId }: { darkstoreId: Darkstore['id'] }): Promise<Banner[]> => {
  const res = await bazarApiClient.get('admin/banners', { searchParams: { darkstoreId } });
  const data = await res.json<any[]>();
  return data.map(item => ({
    id: item.id,
    darkstoreId,
    size: item.isBig ? BannerSize.Big : BannerSize.Small,
    actionType: item.type,
    appImage: `${FILESTORAGE_URL}/${item.imgApp}`,
    webImage: `${FILESTORAGE_URL}/${item.imgWeb}`,
    targetCategory: null,
    targetSubcategory: null,
    targetBrand: null,
    targetScreen: null,
    modal: null,
    stories: [],
  }));
};

const normalizeVariables = (variables: any) => {
  variables.type = variables.actionType;
  variables.targetCategory = variables.categoryId;
  variables.targetSubcategory = variables.subCategory;
  variables.darkstores = [{ id: variables.darkstoreId }];
  variables.imgWeb = variables.webImage.replace('https://bazar-store.by/', '');
  variables.imgApp = variables.appImage.replace('https://bazar-store.by/', '');
  variables.images = variables.image
    ? [{ image: variables.image.replace('https://bazar-store.by/', '') }]
    : [];
  variables.action = variables.actionType === 'subCategory' ? 'category' : variables.actionType;
  variables.actionType = variables.actionType === 'subCategory' ? 'category' : variables.actionType;
  return variables;
};

export const add = async (variables: Omit<Banner, 'id'>) => {
  const normalizedVariables = normalizeVariables(variables);
  const body = JSON.stringify(normalizedVariables);
  const res = await bazarApiClient.post('admin/banners', { body });
  const data = await res.json();
  return data;
};

export const edit = async (variables: Banner) => {
  const body = JSON.stringify(variables);
  const res = await bazarApiClient.put('admin/banners', { body });
  const data = await res.json();
  return data;
};

const remove = async ({ id }: { id: Banner['id'] }) => {
  const res = await bazarApiClient.delete(`admin/banners/${id}`);
  const data = await res.json();
  return data;
};

export const banners = { getList, add, edit, remove };
