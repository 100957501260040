import { Order, OrderProduct } from 'shared/lib/bazar-api';
import { useEditOrderProduct } from '../hooks';
import { OrderProductForm } from './order-product-form';

export const OrderProductEditModal = ({
  orderId,
  product,
  onClose,
}: {
  orderId: Order['id'];
  product: OrderProduct;
  onClose: () => void;
}) => {
  const { isEditing, edit } = useEditOrderProduct({ onSuccess: onClose });

  return (
    <OrderProductForm
      isSubmitting={isEditing}
      mode="edit"
      defaultValues={{ productId: product.id, productName: product.name, amount: product.amount }}
      onSubmit={data => edit({ orderId, data })}
    />
  );
};
