import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import type { CustomerRow } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid, useDataGridState } from 'shared/lib/data-grid';
import { formatDate } from 'shared/utils/date';
import { formatPhone } from 'shared/utils/phone';
import { useCustomers } from '../hooks';

export const CustomersDataGrid = ({ query }: { query: string }) => {
  const { t } = useTranslation('features/customers', { keyPrefix: 'customers-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<CustomerRow>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={value.toString()}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('name', { header: () => t('columns.1') }),
      columnHelper.accessor('phone', {
        header: () => t('columns.2'),
        cell: params => formatPhone(params.getValue()),
      }),
      columnHelper.accessor('ordersAmount', { header: () => t('columns.3') }),
      columnHelper.accessor('ordersSum', { header: () => t('columns.4') }),
      columnHelper.accessor('averageOrderSum', { header: () => t('columns.5') }),
      columnHelper.accessor('averageOrderSize', { header: () => t('columns.6') }),
      columnHelper.accessor('lastOrderedAt', {
        header: () => t('columns.7'),
        cell: params => {
          const value = params.getValue();
          if (value) {
            return formatDate(value, 'DD.MM.YYYY');
          }
          return '';
        },
      }),
    ];
  }, [t]);

  const [debouncedQuery] = useDebouncedValue(query, 300);

  const [state, setState] = useDataGridState({
    sorting: [{ id: 'id', desc: false }],
    pagination: { pageIndex: 0, pageSize: 30 },
  });

  const { isFetching, customers, totalPages } = useCustomers({
    query: debouncedQuery,
    ...state.server,
  });

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={customers}
      sorting
      manualSorting
      pagination
      manualPagination
      pageCount={totalPages}
      state={state}
      onStateChange={setState}
    />
  );
};
