import { User } from 'shared/lib/bazar-api';
import { useEditUser } from '../hooks';
import { UserForm } from './users-form';

export const UserEditModal = ({ user, onClose }: { user: User; onClose: () => void }) => {
  const { isEditing, edit } = useEditUser({ onSuccess: onClose });

  return (
    <UserForm
      mode="edit"
      isSubmitting={isEditing}
      defaultValues={user}
      onSubmit={data => edit({ userId: user.id, data })}
    />
  );
};
