export const AssortmentIcon = ({
  size = 24,
  color = 'currentColor',
  ...restProps
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.313 25.901h-3.667c-.38 0-.688-.292-.688-.653V24.16h-2.75v1.088c0 .36-.308.653-.687.653h-5.042c-.38 0-.687-.292-.687-.653V24.16h-2.75v1.088c0 .36-.308.653-.688.653H5.688c-.38 0-.688-.292-.688-.653v-2.83h22v2.83c0 .36-.308.653-.688.653ZM19.668 5h-2.75v1.742c0 .48-.41.87-.917.87-.506 0-.916-.39-.916-.87V5h-2.75c-.506 0-.917.39-.917.87v5.226c0 .48.41.87.917.87h7.333c.506 0 .917-.39.917-.87V5.87c0-.48-.411-.871-.917-.871ZM14.167 13.71h-2.75v1.743c0 .48-.41.87-.917.87-.506 0-.916-.39-.916-.87V13.71h-2.75c-.506 0-.917.39-.917.87v5.226c0 .48.41.87.917.87h7.333c.506 0 .917-.39.917-.87v-5.225c0-.48-.411-.871-.917-.871ZM25.168 13.71h-2.75v1.743c0 .48-.41.87-.917.87-.506 0-.916-.39-.916-.87V13.71h-2.75c-.506 0-.917.39-.917.87v5.226c0 .48.41.87.917.87h7.333c.506 0 .917-.39.917-.87v-5.225c0-.48-.411-.871-.917-.871Z"
      />
    </svg>
  );
};
