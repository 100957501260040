import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useSearchProducts = ({ query }: { query: string }) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['search-products', { darkstoreId, query }],
    queryFn: () => bazarApi.assortment.searchProducts({ darkstoreId, query }),
    enabled: !!query,
  });

  return { isFetching, products: data ?? [] };
};
