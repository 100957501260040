import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'features/auth';
import { useSettings } from 'features/settings';
import { bazarApi, User } from 'shared/lib/bazar-api';

type EditUserPermissionsDTO = Pick<User, 'id' | 'email' | 'acl'>;

export const useEditUserPermissions = () => {
  const queryClient = useQueryClient();

  const auth = useAuth();

  const partnerId = useSettings(settings => settings.partnerId);

  const { isLoading, mutate } = useMutation<unknown, Error, EditUserPermissionsDTO>({
    mutationFn: variables => {
      return bazarApi.users.editPermissions(variables);
    },
    onMutate: variables => {
      queryClient.setQueryData<User[]>(['users', { partnerId, query: '' }], data => {
        if (data) {
          const newData: User[] = JSON.parse(JSON.stringify(data));
          const index = data.findIndex(item => item.id === variables.id);
          newData[index].acl = { ...variables.acl };
          return newData;
        }
      });
    },
    onSuccess: (_, variables) => {
      if (auth.user && auth.user.email === variables.email) {
        queryClient.invalidateQueries(['user']);
      }
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
    },
    onError: error => {
      queryClient.invalidateQueries(['users']);
      showNotification({
        title: 'Ошибка',
        message: error.message,
        color: 'red',
      });
    },
  });

  return { isEditing: isLoading, edit: mutate };
};
