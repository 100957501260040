import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Order, bazarApi } from 'shared/lib/bazar-api';
import type { OrderFormValues } from '../types';

type EditOrderDTO = {
  orderId: Order['id'];
  data: Partial<OrderFormValues>;
};

export const useEditOrder = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, EditOrderDTO>({
    mutationFn: variables => {
      return bazarApi.orders.edit({
        id: variables.orderId,
        ...variables.data,
      });
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
  });

  return { isEditing: isLoading, edit: mutate };
};
