import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Supply, SupplyProduct } from 'shared/lib/bazar-api';

type RemoveSupplyProductDTO = {
  supplyId: Supply['id'];
  productId: SupplyProduct['id'];
};

export const useRemoveSupplyProduct = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: RemoveSupplyProductDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, RemoveSupplyProductDTO>({
    mutationFn: variables => bazarApi.supplies.removeProduct(variables),
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Товар удален',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['supplies', { supplyId: variables.supplyId }] });
      onSuccess(data, variables);
    },
  });

  return { isRemoving: isLoading, remove: mutate };
};
