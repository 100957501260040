import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useCash = ({ date }: { date: Date }) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['cash', { darkstoreId, date }],
    queryFn: () => bazarApi.cash.getList({ darkstoreId, date }),
  });

  return { isFetching, cash: data ?? [] };
};
