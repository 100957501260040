import { FILESTORAGE_URL } from 'shared/config/env';
import { bazarApiClient } from '../client';
import { Darkstore, Employee, EmployeeRole } from '../types';

const getList = async ({
  darkstoreId,
  role,
  query,
  sortBy = 'surname',
  sortOrder = 'ASC',
  pageNumber = 1,
  pageSize = 10,
}: {
  darkstoreId: Darkstore['id'];
  role: EmployeeRole;
  query: string;
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{
  rows: Employee[];
  totalPages: number;
}> => {
  const body = JSON.stringify({
    darkstoreId,
    role,
    query,
    sortField: sortBy,
    sortOrder,
    page: pageNumber,
    pageSize,
  });
  const res = await bazarApiClient.post('admin/employees/list', { body });
  const data = await res.json<{ rows: any[]; rowsCount: number }>();
  return {
    rows: data.rows.map(row => ({ ...row, photo: `${FILESTORAGE_URL}/${row.photo}` })),
    totalPages: Number((data.rowsCount / pageSize).toFixed(0)),
  };
};

const add = async (
  variables: Omit<Employee, 'id' | 'status'> & { darkstoreId: Darkstore['id'] },
) => {
  const body = JSON.stringify({
    ...variables,
    photo: variables.photo.replace(`${FILESTORAGE_URL}/`, ''),
  });
  const res = await bazarApiClient.post('employees', { body });
  const data = await res.json();
  return data;
};

const remove = async ({ id }: Pick<Employee, 'id'>) => {
  const res = await bazarApiClient.delete(`employees/${id}`);
  const data = await res.json();
  return data;
};

export const employees = { getList, add, remove };
