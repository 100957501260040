import { Navigate, useLocation } from 'react-router-dom';
import { get } from 'shared/utils/object';
import { useAuth } from '../hooks';

export const Protected = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  const auth = useAuth();

  if (!auth.user) {
    return <Navigate to={`/sign-in?redirect=${location.pathname}`} />;
  }

  const permissionName = location.pathname.split('/')[1];

  if (permissionName) {
    const hasReadPermission = !!get(auth.user.acl, permissionName);

    if (!hasReadPermission) {
      // return <Navigate to="/" replace />;
    }
  }

  return children as JSX.Element;
};
