import { BlacklistAddressType } from 'shared/lib/bazar-api';

export const BLACKLIST_ADDRESS_TYPES = [
  { label: 'Использование', value: BlacklistAddressType.Using },
  { label: 'Виральность', value: BlacklistAddressType.Virality },
];

export const BLACKLIST_ADDRESS_TYPES_MAP = {
  [BlacklistAddressType.Using]: 'Использование',
  [BlacklistAddressType.Virality]: 'Виральность',
};
