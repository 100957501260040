import { Badge, MantineSize } from '@mantine/core';
import { SupplyStatus } from 'shared/lib/bazar-api';
import { SUPPLY_STATUSES_MAP } from '../constants';

const COLORS_MAP = {
  [SupplyStatus.Completed]: 'green',
  [SupplyStatus.Pending]: 'yellow',
  [SupplyStatus.Canceled]: 'gray',
};

export const SupplyStatusBadge = ({
  status,
  size,
}: {
  status: SupplyStatus;
  size?: MantineSize;
}) => {
  return (
    <Badge color={COLORS_MAP[status]} size={size}>
      {SUPPLY_STATUSES_MAP[status]}
    </Badge>
  );
};
