import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Select, SelectProps } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { Category } from 'shared/lib/bazar-api';
import { useCategories } from '../hooks';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: Category['id'] | null;
  onChange: (value: Category['id']) => void;
};

export const CategorySelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    const { t } = useTranslation('features/categories', { keyPrefix: 'category-select' });

    const { isFetching, categories } = useCategories();

    const data: SelectItem[] = categories.map(c => ({
      label: c.name,
      value: c.id.toString(),
    }));

    return (
      <Select
        ref={ref}
        searchable
        placeholder={t('select-category')}
        data={data}
        value={value?.toString()}
        rightSection={isFetching && <Loader size="xs" />}
        onChange={newValue => onChange(Number(newValue))}
        {...rest}
      />
    );
  },
);
